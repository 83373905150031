import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import { formRegEx, formRegExReplacer } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";

const AddAndEditFaqModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
  states: statesData,
}) => {
  const [question, setQuestion] = useState(data?.question || "");
  const [answer, setAnswer] = useState(data?.answer || "");
  const [state, setState] = useState(data?.state || "");

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    // if (state.length === 0) return toast.warn("State is required");

    const params = {
      question,
      answer,
      ...(state.length > 0 && { state: state.map((e) => e?._id) }),
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required.`
        );

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Testimonial
        </span>

        {/* States */}
        <div>
          <label>Select States</label>
          <Select
            defaultValue={state}
            isMulti
            options={statesData}
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={(option) => option.state}
            getOptionValue={(option) => option._id}
            onChange={(e) => {
              setState(e);
            }}
          />
        </div>

        {/* {/ Question /} */}
        <div className="mt-3">
          <label>Question</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Question Here"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
        </div>

        {/* {/ Answer /} */}
        <div className="mt-3">
          <label>Answer</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Answer Here"
            value={answer}
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
          />
        </div>

        {/* {/ btn /} */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditFaqModal;
