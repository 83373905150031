import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import SideNavbar from "./SideNavbar";
import { ToggleDrawer } from "../redux/auth/authSlice";
import { useDispatch } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

const ScreenBoiler = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.authReducer.isLogin);
  const drawerOpen = useSelector((state) => state.authReducer.isOpen);

  const toggleDrawerHandler = () => {
    dispatch(ToggleDrawer(!drawerOpen));
  };

  useEffect(() => {
    // if (window.innerWidth < 992) {
    //   dispatch(ToggleDrawer(true));
    // } else {
    //   dispatch(ToggleDrawer(false));
    // }

    console.log("effect running");
  }, [window.innerWidth]);

  return (
    <Container fluid>
      <div className="sidebarContentWrapper">
        {isAuthenticated && (
          <div className={drawerOpen ? "sidebarOpen" : "sideabarClose"}>
            <div className="toggleDrawerBtn" onClick={toggleDrawerHandler}>
              <AiOutlineClose size={28} />
            </div>
            <SideNavbar toggleDrawerHandler={toggleDrawerHandler} />
          </div>
        )}

        <div
          className={`${
            isAuthenticated
              ? drawerOpen
                ? "sidebarOpenContent"
                : "sidebarCloseContent"
              : "sidebarContentNotAuthenticated"
          }`}
        >
          <div className="toggleDrawerBtn" onClick={toggleDrawerHandler}>
            <GiHamburgerMenu size={28} />
          </div>
          {children}
        </div>
      </div>
    </Container>
  );
};

export default ScreenBoiler;
