import React, { useState } from "react";
import { toast } from "react-toastify";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { formRegEx, formRegExReplacer } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";

const AddEditEditCertificatesModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
}) => {
  const [certificateId, setCertificateId] = useState(data?.certificateId || "");
  const [type, setType] = useState(data?.type || "unused");

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    // if (state.length === 0) return toast.warn("State is required");

    const params = {
      certificateId,
      type,
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required.`
        );

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Certificate
        </span>

        {/* {/ Certificate Id /} */}
        <div className="mt-3">
          <label>Certificate Id</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Certificate Id Here"
            value={certificateId}
            onChange={(e) => {
              setCertificateId(e.target.value);
            }}
          />
        </div>

        {/* type */}
        <div className="mt-3">
          <label>Status</label>
          <FormControl component="fieldset" className="d-b">
            <RadioGroup
              row
              aria-label="status"
              name="controlled-radio-buttons-group"
              value={type}
              onChange={(e) => setType(e?.target?.value)}
            >
              <FormControlLabel
                value={"used"}
                control={<Radio />}
                label="Used"
              />
              <FormControlLabel
                value={"unused"}
                control={<Radio />}
                label="Unused"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {/* {/ btn /} */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddEditEditCertificatesModal;
