import { green } from "@mui/material/colors";
import React, { useImperativeHandle, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { formRegEx, formRegExReplacer, imageUrl } from "../Config/apiUrl";

const CMSTriangleDataMananger = ({
  activeRef,
  title,
  titleLabel = "Enter Title",
  title2,
  title2Label = null,
  description,
  descriptionLabel = "Enter Description",
  image,

  title3 = null,
  title3Label,
  description3 = null,
  description3Label,
  heading = "Hero Section",
  hr = false,
  id = Math.random(),
}) => {
  const [titleValue, setTitleValue] = useState(title || "");
  const [title2Value, setTitle2Value] = useState(title2 || "");
  const [descriptionValue, setDescriptionValue] = useState(description || "");
  const [title3Value, setTitle3Value] = useState(title3 || "");
  const [description3Value, setDescription3Value] = useState(
    description3 || ""
  );

  // IMAGE
  const [selectedImage, setSelectedImage] = useState(image || undefined);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onload = (event) => {
      setSelectedImage(event.target.result);

      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        // setVideoDuration(millisToMinutesAndSeconds(media.duration * 1000));
      };
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  //   handleSubmit
  const handleSubmit = () => {
    const params = {
      title: titleValue,
      ...(title2Value !== "" && { title2: title2Value }),
      description: descriptionValue,
       image: selectedImageFile || selectedImage,
      ...(title3Value !== "" && { title3: title3Value }),
      ...(description3Value !== "" && { description3: description3Value }),
    };

    // validate
    for (let key in params)
      if (params[key] === "") {
        toast.warn(`${key.replace(formRegEx, formRegExReplacer)} is required.`);
        return "error";
      }

    // validateImage
    if (!selectedImageFile && !selectedImage) {
      toast.warn(`Image is required.`);
      return "error";
    }

    return params;
  };

  useImperativeHandle(activeRef, () => ({
    handleSubmit,
  }));

  return (
    <Container>
      {hr && <hr className="mt-3 mb-3" />}
      <h3>{heading}</h3>
      <Row>
        {/* Title */}
        <div className="mt-3 col-md-6">
          <label>{titleLabel}</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder={`${titleLabel} Here`}
            value={titleValue}
            onChange={(e) => {
              setTitleValue(e.target.value);
            }}
          />
        </div>
        {/* title 2 */}
        {title2Label && (
          <div className="mt-3 col-md-6">
            <label>{title2Label}</label>
            <input
              className="add-evidence-modal-input full"
              type="text"
              placeholder={`${title2Label} Here`}
              value={title2Value}
              onChange={(e) => {
                setTitle2Value(e.target.value);
              }}
            />
          </div>
        )}
        {/* Description */}
        <div className="mt-3 col-md-12">
          <label>{descriptionLabel}</label>
          <textarea
            className="form-control"
            rows={5}
            type="text"
            placeholder={`${descriptionLabel} Here`}
            value={descriptionValue}
            onChange={(e) => {
              setDescriptionValue(e.target.value);
            }}
          />
        </div>

        <div className="mt-3 col-md-6">
          {/* Video */}
          <div class="form-group" className="seo__imagePicker mt-3">
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              name="uploadfile"
              id={id}
              style={{
                display: "none",
              }}
              onChange={(event) => {
                handleSelectImage(event);
              }}
            />
            <label for="exampleInputEmail1">Select Image</label>
            <label
              for={id}
              className="btn btn-primary btn-lg btn-block"
              style={{
                backgroundColor: green[500],
              }}
              onChange={(e) => {
                // console.log(e.target.files[0]);
              }}
            >
              {selectedImage ? "Change selection" : "Pick an Image"}
            </label>
          </div>

          {/* Shoe Image */}
          {selectedImage && (
            <div className="d-flex align-items-center justify-content-center w-100">
              <img
                src={
                  selectedImageFile ? selectedImage : imageUrl(selectedImage)
                }
                alt="selectedImage"
                className="w-50 mt-3"
                style={{
                  width: "200px !important",
                }}
              />
            </div>
          )}
        </div>
        <div className="col-md-6" />
        {/* Title 3 */}
        {title3Label && (
          <div className="mt-3 col-md-6">
            <label>{title3Label}</label>
            <input
              className="add-evidence-modal-input full"
              type="text"
              placeholder={`${title3Label} Here`}
              value={title3Value}
              onChange={(e) => {
                setTitle3Value(e.target.value);
              }}
            />
          </div>
        )}
        {/* Description 3 */}
        {description3 && (
          <div className="mt-3 col-md-12">
            <label>{description3Label}</label>
            <textarea
              className="form-control"
              rows={5}
              type="text"
              placeholder={`${description3Label} Here`}
              value={description3Value}
              onChange={(e) => {
                setDescription3Value(e.target.value);
              }}
            />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default CMSTriangleDataMananger;
