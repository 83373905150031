import React, { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { MdOutlineLock } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import FotgetPasswordModal from "../components/FotgetPasswordModal";
import ScreenBoiler from "../components/ScreenBoiler";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import { saveLoginUserData } from "../redux/auth/authSlice";

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [showForgetPasswordModal, setShowforgetPasswordModal] = useState(false);

  const handleChange = (e, setter) => {
    const { id, value } = e.target;
    setter(value);
  };

  async function handleLogin(e) {
    setIsLogging(true);
    e.preventDefault();
    const loginURL = BaseURL("users/admin-login");
    let params = {
      email: email,
      password: password,
      role: "admin",
    };

    for (let key in params) {
      if (params[key] === "") return toast.warn(`${key} is required`);
    }

    const responseData = await Post(loginURL, params, apiHeader());
    if (responseData !== undefined) {
      setIsLogging(false);
      dispatch(saveLoginUserData(responseData?.data));
      navigate("/");
    } else {
      setIsLogging(false);
    }
  }

  return (
    <ScreenBoiler>
      <div className="limiter">
        <div
          className="container-login100"
        >
          <div
            className="wrap-login100"
            style={{ border: "1px solid lightgrey" }}
          >
            <form className="login100-form validate-form">
              <span className="login100-form-title"> Login </span>

              <div
                className="wrap-input100 validate-input"
                data-validate="Username is reauired"
              >
                <span className="label-input100">Email</span>
                <input
                  className="input100"
                  type="text"
                  name="username"
                  placeholder="Type your email"
                  value={email}
                  onChange={(e) => {
                    handleChange(e, setEmail);
                  }}
                />
                <span className="focus-input100">
                  <AiOutlineMail size={23} />
                </span>
              </div>

              <div
                className="wrap-input100 validate-input"
                data-validate="Password is required"
              >
                <span className="label-input100">Password</span>
                <input
                  className="input100"
                  type="password"
                  name="password"
                  placeholder="Type your password"
                  value={password}
                  onChange={(e) => {
                    handleChange(e, setPassword);
                  }}
                />
                <span className="focus-input100">
                  <MdOutlineLock size={23} />
                </span>
              </div>

              <div className="container-login100-form-btn">
                <div className="wrap-login100-form-btn">
                  <div className="login100-form-bgbtn"></div>
                  <button
                    className="login100-form-btn"
                    onClick={handleLogin}
                    disabled={isLogging}
                  >
                    {isLogging ? "Logging..." : "Login"}
                  </button>
                </div>
              </div>
            </form>
            <a
              style={{
                marginTop: "20px",
                cursor: "pointer",
              }}
              onClick={() => setShowforgetPasswordModal(true)}
            >
              Forgot Password?
            </a>
          </div>
        </div>
      </div>

      <FotgetPasswordModal
        open={showForgetPasswordModal}
        setOpen={setShowforgetPasswordModal}
      />
    </ScreenBoiler>
  );
};

export default Login;
