import React, { useEffect } from "react";
import { Get, Post } from "../../Axios/AxiosFunctions";
import ScreenBoiler from "../../components/ScreenBoiler";
import { apiHeader, BaseURL } from "../../Config/apiUrl";
import classes from "./Dashboard.module.css";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

const Dashboard = () => {
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const [statesData, setStatesData] = React.useState([]);
  const [statsData, setStatsData] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [isGetting, setIsGetting] = React.useState(false);

  const data = Array(50)
    .fill({ name: "D", value: 1 })
    .map((e, i) => ({ ...e, name: e.name + i, value: e.value + i }));

  // getData
  const getData = async () => {
    const url = BaseURL(`state?noPagination=true`);

    setIsGetting(true);
    const [stateResponse] = await Promise.allSettled([Get(url, accessToken)]);
    setIsGetting(false);
    if (stateResponse?.value !== undefined) {
      setStatesData([
        {
          state: "All",
          _id: "all",
        },
        ...stateResponse.value?.data?.data,
      ]);
    }
  };

  // getStatsData
  const getStatsData = async () => {
    if (!selectedState) return toast.warn("Please select a state first.");
    if (!startDate) return toast.warn("Please select a start date.");
    if (!endDate) return toast.warn("Please select a end date.");

    if (new Date(startDate) > new Date(endDate)) {
      return toast.warn("Start date must be less than end date.");
    }

    const url = BaseURL(`users/admin/dashboardStats`);
    const params = {
      startDate,
      endDate,
      state: selectedState?._id,
    };

    setIsGetting(true);
    const r = await Post(url, params, apiHeader(accessToken));
    setIsGetting(false);
    if (r !== undefined) {
      setStatsData(r?.data?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Dashboard</h1>
        </div>
      </section>
      <div id="root">
        <div className="mt-3">
          <label>Select States</label>
          <Select
            options={statesData}
            value={selectedState}
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={(option) => option.state}
            getOptionValue={(option) => option._id}
            onChange={(e) => {
              setSelectedState(e);
            }}
          />
        </div>
        <Row>
          <Col>
            <div className="mt-3">
              <label>Start Date</label>
              <input
                className="add-evidence-modal-input full"
                type="date"
                placeholder="start date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col>
            <div className="mt-3">
              <label>End Date</label>
              <input
                className="add-evidence-modal-input full"
                type="date"
                placeholder="End date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <li
            className="page-item align-self-end ml-4 mt-3 d-flex justify-content-end"
            onClick={getStatsData}
            disabled={isGetting}
          >
            <Button className="delete-btn">
              {isGetting ? "Please Wait..." : "View Detail"}
            </Button>
          </li>
        </Row>
        <div className="container pt-5 pb-5">
          <div className="row align-items-stretch gy-4">
            {statsData?.length > 0 &&
              statsData.map((e) => (
                <div
                  className={`${classes.cDashboardInfo} col-lg-3 col-md-4 col-sm-6 col-xs-12`}
                >
                  <div className={classes.wrap}>
                    <h4
                      className={`heading heading5 hind-font medium-font-weight ${classes.cDashboardInfo__title}`}
                    >
                      {e?.name}
                    </h4>
                    <span
                      className={`hind-font caption-12 ${classes.cDashboardInfo__count}`}
                    >
                      {e?.value}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </ScreenBoiler>
  );
};

export default Dashboard;
