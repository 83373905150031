import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import AreYouSureModal from "../components/AreYouSureModal";
import Pagination from "../components/PaginationComponent";
import TableRowsLoader from "../components/TableRowsLoader";
// Mui Button
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddAndEditLessonModal from "../components/AddAndEditLessonModal";
import ScreenBoiler from "../components/ScreenBoiler";

function LessonsScreen() {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLessons, setTotalLessons] = useState(0);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isAddEditApiCall, setIsAddEditApiCall] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [activeInActiveApiCall, setActiveInActiveApiCall] = useState(false);

  // get Item handler
  const getData = async (pageNo) => {
    const url = BaseURL(`lesson?page=${pageNo}&limit=${limit}`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
      setTotalLessons(response?.data?.totalRecords);
    }
  };

  // addAndupdateItemHandler
  const addAndupdateItemHandler = async (params) => {
    const url = BaseURL(
      editingData ? `lesson/admin/${editingData?._id}` : `lesson/admin`
    );
    const fn = editingData?._id ? Patch : Post;

    setIsAddEditApiCall(true);
    const response = await fn(url, params, apiHeader(accessToken));
    setIsAddEditApiCall(false);

    if (response !== undefined) {
      // if we have updated
      if (editingData) {
        const dataCopy = [...apiData];
        const index = dataCopy.findIndex(
          (item) => item._id === editingData._id
        );
        dataCopy[index] = response.data?.data;
        setApiData(dataCopy);
      }
      // if we have added
      else {
        setApiData((p) => [response.data?.data, ...p]);
      }

      toast.success(`Lesson ${editingData ? "Updated" : "Added"} Successfully`);
      setEditingData(null);
      setIsAddAndEditModalOpen(false);
    }
  };

  // deleteItemHandler
  const deleteItemHandler = async () => {
    const url = BaseURL(`lesson/admin/${deletingId}`);

    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === deletingId);
      dataCopy.splice(index, 1);
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(`Lesson deleted succeccfully!`);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber]);

  // pagination handler
  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getData(newPageNumber, limit);
  };

  // handleActiveInactive
  const handleActiveInactive = async (isActive, index, id) => {
    const url = BaseURL(`lesson/admin/${id}`);
    const params = {
      isActive,
    };

    setActiveInActiveApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setActiveInActiveApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      dataCopy[index].isActive = isActive;
      setApiData(dataCopy);
      toast.success(
        `Lesson ${isActive ? "Activated" : "Deactivated"} Successfully`
      );
    }
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Lessons</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">

            <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => {
                  setEditingData(null);
                  setIsAddAndEditModalOpen(true);
                }}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div>
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>No. of Topics</th>
                      <th>Active</th>
                      <th>Last Updated</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td className="t-t-c">{item?.lessonName}</td>
                          <td>{item?.topics?.length}</td>
                          <td>{item?.isActive ? "Yes" : "No"}</td>
                          <td>
                            {moment(item?.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end me-2"
                                onClick={() => {
                                  setEditingData(item);
                                  setIsAddAndEditModalOpen(true);
                                }}
                              >
                                <Button className="update-btn">Edit</Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsAreYouSureModalOpen(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4 ms-2 "
                                onClick={handleActiveInactive.bind(
                                  this,
                                  !item?.isActive,
                                  index - 1,
                                  item?._id
                                )}
                              >
                                <Button
                                  className="update-btn w-120px"
                                  disabled={activeInActiveApiCall}
                                >
                                  {item?.isActive ? "Deactivate" : "Activate"}
                                </Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4 ms-2"
                                onClick={() => navigate(`/topic/${item?._id}`)}
                              >
                                <Button className="delete-btn">
                                  View Topics
                                </Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className="page">
          {apiData.length>0 && <Pagination
              totalPages={Math.ceil(totalLessons / limit)}
              setCurrentPage={setPageNumber}
              currentPage={pageNumber}
            />}
          </div>
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={`Do you really want to delete this Lesson?`}
            handleYes={deleteItemHandler}
            isLoading={isDeleteApiCall}
          />
        )}

        {isAddAndEditModalOpen && (
          <AddAndEditLessonModal
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            handleSubmit={addAndupdateItemHandler}
            data={editingData}
            isLoading={isAddEditApiCall}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default LessonsScreen;
