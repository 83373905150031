import React from "react";
import { useSelector } from "react-redux";
import { Navigate, } from "react-router-dom";

function ProtectedRoute({ component, path, children, ...restOfProps }) {
  const isAuthenticated = useSelector((state) => state.authReducer.isLogin);
  // const isAuthenticated = true;

  return (
    // <Route
    //   {...restOfProps}
    //   render={(props) =>
    //     isAuthenticated == true ? <Component {...props} /> : <Navigate to="/" />
    //   }
    // />
    <div>{isAuthenticated == true ? component : <Navigate to="/login" />}</div>
  );
}

export default ProtectedRoute;
