import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import ModalSkeleton from "./ModalSkeleton";
import PopOverUser from "./PopOverUser";
import { IconButton } from "@mui/material";
import { Button, Col, Row } from "react-bootstrap";
import ShowMoreShowLessText from "./ShowMoreShowLessText";
import moment from "moment";
import { BaseURL, apiHeader, imageUrl } from "../Config/apiUrl";
import PopOverUserTopicDetail from "./PopOverUserTopicDetail";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { Delete, Patch, Post } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { BiLoaderCircle } from "react-icons/bi";
import PopOverSecurityQuestionsDetail from "./PopOverSecurityQuestionsDetail";

const tagLine = {
  l1: {
    "tennessee": "Send Certificate To Homeland Security",
    "idaho": "Send Certificate To Transportation Department",
  },
  l2: {
    "tennessee": "Certificate Sent To Homeland Security",
    "idaho": "Certificate Sent To Transportation Department",
  }
}

const UserDetailModal = ({ open, setOpen, data: allData }) => {
  const { data, user } = allData;
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const certificatePdfs = user?.progress?.map((c) => c?.certificatePdf);
  const [notes, setNotes] = React.useState(user?.notes || []);
  const [note, setNote] = React.useState({
    text: "",
    data: null,
  });
  const [loading, setLoading] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  // addOrEditNoteHandler
  const addOrEditNoteHandler = async () => {
    if (!note?.text) return toast.warn("Please enter note text");
    const params = {
      text: note?.text,
      ...(note?.data && { noteId: note?.data?._id }),
    };

    const url = BaseURL(
      note?.data
        ? `notes/admin/update/${user?._id}`
        : `notes/admin/create/${user?._id}`
    );
    const fn = note?.data ? Patch : Post;
    setLoading(true);
    const res = await fn(url, params, apiHeader(accessToken));
    setLoading(false);

    if (res) {
      const newNote = res?.data?.data?.notes;
      setNotes([...newNote]);
      setNote({
        data: null,
        text: "",
      });
      toast.success(`${note?.data ? "Updated" : "Added"} Successfully.`);
    }
  };

  // handleDelete
  const handleDelete = async (__note) => {
    if (!__note) return;
    const url = BaseURL(`notes/admin/delete/${user?._id}/${__note?._id}`);
    setLoading(true);
    setIsDeleting(__note?._id);
    const res = await Delete(url, null, apiHeader(accessToken));
    setLoading(false);
    setIsDeleting(false);
    if (res) {
      const _notes = [...notes];
      const index = notes.findIndex((e) => e?._id === __note?._id);
      if (index > -1) {
        _notes.splice(index, 1);
        setNotes([..._notes]);
      }
      setNote({
        data: null,
        text: "",
      });
      toast.success(`Note deleted successfully.`);
    }
  };

  // renderNotesCRUD
  const renderNotesCRUD = () => {
    return (
      <div className="">
        <p className="detailHeading">Notes</p>

        {/* Add Container */}
        <div className={"notes__addContainer"}>
          <Col md={12}>
            <textarea
              placeholder="Enter here..."
              rows={3}
              value={note.text}
              onChange={(e) => setNote((p) => ({ ...p, text: e.target.value }))}
            />
            <div className={"notes__addBtnContainer"}>
              {note?.data && (
                <Button
                  disabled={loading}
                  onClick={() => {
                    setNote({
                      data: null,
                      text: "",
                    });
                  }}
                  className="delete-btn me-2"
                >
                  Cancel
                </Button>
              )}
              <Button
                disabled={loading}
                onClick={addOrEditNoteHandler}
                className="update-btn"
              >
                {loading
                  ? "Please Wait..."
                  : note?.data
                  ? "Edit Note"
                  : "Add Note"}
              </Button>
            </div>
          </Col>
        </div>
        {/* render notes  */}
        <div className={"notes__listContainer"}>
          {notes?.map((_note, i) => (
            <div key={i}>
              <div className="noteList__left">
                <p>
                  <ShowMoreShowLessText visibility={150} text={_note?.text} />
                </p>
                <span>
                  Last Updated: {moment(_note?.updatedAt).format("lll")}
                </span>
              </div>
              <div className="noteList__right">
                <span
                  onClick={() => {
                    if (loading) return;
                    setNote({
                      data: _note,
                      text: _note?.text,
                    });
                  }}
                >
                  <AiFillEdit />
                </span>
                <span onClick={handleDelete.bind(this, _note)}>
                  {isDeleting === _note?._id ? (
                    <BiLoaderCircle />
                  ) : (
                    <AiFillDelete />
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen} type="big">
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {/* {user?.userName} */}
          {[user?.firstName, user?.lastName].join(" ")}{" "}
        </span>

        {/* state and Package */}
        <Row>
          <Col md={6}>
            <div className={"listConatiner"}>
              <p>
                State:{" "}
                <span className={"historyListRight t-t-c"}>
                  {data?.state?.state}
                </span>
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className={"listConatiner"}>
              <p>
                Package Type:{" "}
                <span className={"historyListRight t-t-c"}>
                  {data?.package?.type}
                </span>
              </p>
            </div>
          </Col>
        </Row>

        {/* Xity and zipxode */}
        <Row>
          {["tennessee", "idaho"].includes(data?.state?.state?.toLowerCase()) && (
            <>
              <Col md={12}>
                <div className={"listConatiner"}>
                  <p>
                    {tagLine?.l1?.[
                      data?.state?.state?.toLowerCase()
                    ]} (Add On):{" "}
                    <span className={"historyListRight t-t-c"}>
                      {user?.sendTennesseeCertificateToHomelandSecurity
                        ? "Selected"
                        : "No Selected"}
                    </span>
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className={"listConatiner"}>
                  <p>
                    {tagLine?.l2?.[
                      data?.state?.state?.toLowerCase()
                    ]}:{" "}
                    <span className={"historyListRight t-t-c"}>
                      {user?.isTennesseeCertificateToHomelandSecurity
                        ? "Yes"
                        : "No"}
                    </span>
                  </p>
                </div>
              </Col>
            </>
          )}
          <Col md={6}>
            <div className={"listConatiner"}>
              <p>
                City:{" "}
                <span className={"historyListRight t-t-c"}>{user?.city}</span>
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className={"listConatiner"}>
              <p>
                Zip code:{" "}
                <span className={"historyListRight t-t-c"}>
                  {user?.zipCode}
                </span>
              </p>
            </div>
          </Col>
        </Row>
        {/* Email */}
        <Row>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Email:{" "}
                <span className={"historyListRight"}>
                  <ShowMoreShowLessText text={user?.email} />
                </span>
              </p>
            </div>
          </Col>
        </Row>
        {/* county, courtName, courtCode,   */}
        {["california"].includes(data?.state?.state?.toLowerCase()) && (
          <Row>
            <Col md={6}>
              <div className={"listConatiner"}>
                <p>
                  County:{" "}
                  <span className={"historyListRight"}>
                    <ShowMoreShowLessText text={user?.county || "-"} />
                  </span>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className={"listConatiner"}>
                <p>
                  Court Code:{" "}
                  <span className={"historyListRight"}>
                    <ShowMoreShowLessText text={user?.courtCode || "-"} />
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        )}

        {["california", "missouri"].includes(
          data?.state?.state?.toLowerCase()
        ) && (
          <Col md={12}>
            <div className={"listConatiner"}>
              <p>
                Court Name:{" "}
                <span className={"historyListRight"}>
                  <ShowMoreShowLessText text={user?.courtName || "-"} />
                </span>
              </p>
            </div>
          </Col>
        )}
        {/* leaCode, reference Number */}
        <Row>
          {/* <Col>
            <div className={"listConatiner"}>
              <p>
                Reference Number:{" "}
                <span className={"historyListRight"}>
                  <ShowMoreShowLessText text={user?.referenceNumber} />
                </span>
              </p>
            </div>
          </Col> */}
          {user?.leaCode && (
            <Col>
              <div className={"listConatiner"}>
                <p>
                  LEA Code:{" "}
                  <span className={"historyListRight"}>
                    <ShowMoreShowLessText text={user?.leaCode || "-"} />
                  </span>
                </p>
              </div>
            </Col>
          )}
        </Row>
        {["missouri"].includes(data?.state?.state?.toLowerCase()) && (
          <Row>
            <Col md={6}>
              <div className={"listConatiner"}>
                <p>
                  Gender:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {user?.gender}
                  </span>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className={"listConatiner"}>
                <p>
                  Accident Involved:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {user?.accidentInvolved ? "Yes" : "No"}
                  </span>
                </p>
              </div>
            </Col>

            <Col md={6}>
              <div className={"listConatiner"}>
                <p>
                  Court Originated Number:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {user?.courtOriginatorNumber}
                  </span>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className={"listConatiner"}>
                <p>
                  Telephone Number:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {user?.contact}
                  </span>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className={"listConatiner"}>
                <p>
                  Conviction Date:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {moment(user?.convictionDate).format("ll")}
                  </span>
                </p>
              </div>
            </Col>
            <Col md={12}>
              <div className={"listConatiner"}>
                <p>
                  Violations:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {user?.violations}
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        )}
        {/* caseNumber and deadLineDate and  dateOfBirth */}
        <Row>
          <Col md={4}>
            <div className={"listConatiner"}>
              <p>
                Case ID:{" "}
                <span className={"historyListRight t-t-c"}>
                  {user?.caseNumber}
                </span>
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className={"listConatiner"}>
              <p>
                DeadLine Date:{" "}
                <span className={"historyListRight t-t-c"}>
                  {moment(user?.deadLineDate).format("ll")}
                </span>
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className={"listConatiner"}>
              <p>
                Date of Birth:{" "}
                <span className={"historyListRight t-t-c"}>
                  {moment(user?.dateOfBirth).format("ll")}
                </span>
              </p>
            </div>
          </Col>
        </Row>
        {/* licenseNumber and Package */}
        <Row>
          <Col md={6}>
            <div className={"listConatiner"}>
              <p>
                License Number:{" "}
                <span className={"historyListRight t-t-c"}>
                  {user?.licenseNumber}
                </span>
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className={"listConatiner"}>
              <p>
                License State:{" "}
                <span className={"historyListRight t-t-c"}>
                  {user?.licenseState}
                </span>
              </p>
            </div>
          </Col>
        </Row>
        {/* Address */}
        <Row>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Address:{" "}
                <span className={"historyListRight t-t-c"}>
                  <ShowMoreShowLessText text={user?.address} />
                </span>
              </p>
            </div>
          </Col>
        </Row>

        {/* total courses */}
        <div className={"listConatiner"}>
          <p>
            Total No. of Courses:{" "}
            <span className={"historyListRight t-t-c"}>
              {data?.TotalCourses}
            </span>
          </p>
        </div>

        {/* certificates */}
        {certificatePdfs?.length > 0 && certificatePdfs[0] != null && (
          <div className={"listConatiner"}>
            <p>
              Certificate:{" "}
              {certificatePdfs?.map((e, i) => (
                <span
                  className={"historyListRight t-t-c c-p me-4 "}
                  key={i}
                  onClick={() => {
                    window.open(imageUrl(e), "_blank");
                  }}
                >
                  Click Here
                </span>
              ))}
            </p>
          </div>
        )}

        {/* topic detail */}
        <div className={"listConatiner"}>
          <p>
            Time Stamp:{" "}
            <span className={"historyListRight t-t-c"}>
              <PopOverUserTopicDetail data={user?.media} />
            </span>
          </p>
        </div>

        {/* security questions */}
        {user?.securityQuestions?.length > 0 && (
          <div className={"listConatiner"}>
            <p>
              Security Questions:{" "}
              <span className={"historyListRight t-t-c"}>
                <PopOverSecurityQuestionsDetail
                  data={user?.securityQuestions}
                />
              </span>
            </p>
          </div>
        )}

        {/* Notes CRUD */}
        {renderNotesCRUD()}

        {/* Course Detail Table */}
        <p className="detailHeading">Course(s) Detail</p>
        <div className="innerModalTable">
          <table className="table" id="dataTable" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Current Topic</th>
                <th>Covered Topics</th>
                <th>Current Lesson</th>
                <th>Exam Re-Attempts</th>
                <th>Course Progress</th>
                <th>Exam Status</th>
                <th>Total Attpeted Quiz</th>
                <th>Latest Exam</th>
              </tr>
            </thead>
            <tbody>
              {data?.courseDetails?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.Currenttopic}</td>
                    <td>{item?.coveredTopics}</td>
                    <td>{item?.currentLesson}</td>
                    <td>{item?.examReattempts}</td>
                    <td>
                      {item?.progress > 100
                        ? 100
                        : parseFloat(item?.progress).toFixed(2)}
                    </td>
                    <td>{item?.examStatus}</td>
                    <td>{item?.totalAttpetedQuiz}</td>
                    <td>
                      {item?.latestExam ? (
                        <PopOverUser
                          data={item?.latestExam}
                          afterExamQuestions={item?.afterExamQuestions}
                          evaluations={user?.evaluations}
                          isEvaluated={user?.isEvaluated}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <IconButton className="close-icon-modal" onClick={setOpen}>
          <CloseIcon />
        </IconButton>
      </form>
    </ModalSkeleton>
  );
};

export default UserDetailModal;
