import React, { useState } from "react";
import { toast } from "react-toastify";

import ModalSkeleton from "./ModalSkeleton";

const AddAndEditSecurityQuestionModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
}) => {
  // states
  const [question, setQuestion] = useState(data?.question || "");

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      question,
    };

    // validate
    if (params.question === "") return toast.warn(`Question is required`);

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Question
        </span>

        {/* Question */}
        <div>
          <label>Security Question</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Question Here"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
        </div>

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditSecurityQuestionModal;
