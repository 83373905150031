import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import ShowMoreShowLessText from "./ShowMoreShowLessText";

export default function PopOverSecurityQuestionsDetail({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className="update-btn w-160px"
      >
        View Detail
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        className={"popover-menu-xx"}
      >
        <Box sx={{ width: 600 }}>
          <Row className="p-5">
            <p className="detailHeading2 mb-4">Security Questions:</p>
            {data?.map((e, i) => (
              <Col md={12} className="topicDetailItem" key={i}>
                <div className={"listConatiner"}>
                  <p>
                    Question:{" "}
                    <span className={"historyListRight t-t-c"}>
                      <ShowMoreShowLessText
                        visibility={200}
                        text={e?.question}
                      />
                    </span>
                  </p>
                  <p>
                    Answer:{" "}
                    <span className={"historyListRight t-t-c"}>
                      <ShowMoreShowLessText visibility={200} text={e?.answer} />
                    </span>
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Box>
      </Popover>
    </div>
  );
}
