import React from "react";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import ScreenBoiler from "../../components/ScreenBoiler";
import { apiHeader, BaseURL } from "../../Config/apiUrl";
import classes from "./UpdatePasswordScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState } from "react";
import { saveLoginUserData } from "../../redux/auth/authSlice";

const UpdatePasswordScreen = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isApiCalling, setIsApiCalling] = useState(false);
  // Password
  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [password, setPassword] = useState("");

  // handlePasswordSubmit
  const handlePasswordChange = async () => {
    const url = BaseURL("users/updateMyPassword");

    const params = {
      passwordCurrent,
      password,
      passwordConfirm,
    };

    for (let key in params)
      if (params[key] === "") return toast.warn(`All fields are required.`);

    if (password.length < 8)
      return toast.warn(`Password must be at least 8 characters.`);

    if (password !== passwordConfirm)
      return toast.warn(`Password and confirm password does not match.`);

    if (password === passwordCurrent)
      return toast.warn(`New password and current password can not be same.`);

    setIsApiCalling(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsApiCalling(false);

    if (response !== undefined) {
      toast.success("Password updated successfully.", "top-right");
      dispatch(saveLoginUserData(response?.data));

      // empty states
      setPasswordCurrent("");
      setPasswordConfirm("");
      setPassword("");
    }
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Change Password</h1>
        </div>
      </section>
      <div id="root">
        <Col md={8}>
          <Col md={12} className="mt-4">
            <label>Current Password</label>
            <input
              placeholder="Current Password"
              value={passwordCurrent}
              onChange={(e) => setPasswordCurrent(e.target.value)}
              type="password"
              className="add-evidence-modal-input full"
            />
          </Col>
          <Col md={12} className="mt-4">
            <label>New Password</label>
            <input
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="add-evidence-modal-input full"
            />
          </Col>
          <Col md={12} className="mt-4">
            <label>Confirm Password</label>
            <input
              placeholder="Confirm Password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              type="password"
              className="add-evidence-modal-input full"
            />
          </Col>
          <li
            className="page-item align-self-end ml-4 mt-3 d-flex justify-content-end"
            disabled={isApiCalling}
            onClick={handlePasswordChange}
          >
            <Button className="delete-btn">
              {isApiCalling ? "Please Wait..." : "View Detail"}
            </Button>
          </li>
        </Col>
      </div>
    </ScreenBoiler>
  );
};

export default UpdatePasswordScreen;
