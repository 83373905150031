import React, { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";

import { formRegEx, formRegExReplacer } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";

const AddAndEditCourseModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
  examArray,
  lessonArray,
}) => {
  // states
  const [courseName, setCourseName] = useState(data?.courseName || "");
  const [lesson, setLesson] = useState(data?.lessons || "");
  const [exam, setExam] = useState(
    data?.exam?.filter((e) => e?.examType === "original") || ""
  );

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      courseName,
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    if (lesson.length === 0)
      return toast.warn(`Please add at least one lesson`);

    // if (exam.length === 0) return toast.warn(`Please select an exam`);

    params.lessons = lesson.map((e) => e?._id);
    params.exam = exam.length > 0 ? exam.map((e) => e?._id) : [];

    // if exam is already added then add the copy exam
    if (params?.exam?.length > 0) {
      params.exam = [
        ...params?.exam,
        ...(data?.exam
          ?.filter((e) => e?.examType === "copy")
          ?.map((e) => e?._id) ?? []),
      ];
    }

    params.copyExam = data?.exam
      ?.filter((e) => e?.examType === "copy")
      ?.map((e) => e?._id);

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Course
        </span>

        {/* Course */}
        <div>
          <label>Course Name</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Course Name Here"
            value={courseName}
            onChange={(e) => {
              setCourseName(e.target.value);
            }}
          />
        </div>

        {/* Lesson */}
        <div className="mt-3">
          <label>Select Lessons</label>
          <Select
            defaultValue={lesson}
            isMulti
            options={lessonArray}
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={(option) => option.lessonName}
            getOptionValue={(option) => option.lessonName}
            onChange={(e) => {
              setLesson(e);
            }}
          />
        </div>

        {/* Exam */}
        <div className="mt-3">
          <label>Select Exam</label>
          <Select
            isMulti
            defaultValue={exam}
            options={examArray}
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={(option) => option.examName}
            getOptionValue={(option) => option.examName}
            onChange={(e) => {
              setExam(e);
            }}
          />
        </div>

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditCourseModal;
