import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Patch, Post } from "../Axios/AxiosFunctions";
import { BaseURL, apiHeader, imageUrl, recordsLimit } from "../Config/apiUrl";
import AreYouSureModal from "../components/AreYouSureModal";
import EditUserInfoModal from "../components/EditUserInfoModal";
import Pagination from "../components/PaginationComponent";
import ScreenBoiler from "../components/ScreenBoiler";
import TableRowsLoader from "../components/TableRowsLoader";
import UserDetailModal from "../components/UserDetailModal";
import useDebounce from "../constant/Hooks/useDebounce";

function UsersScreen() {
  const [limit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, settotal] = useState(0);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [search, setSearch] = useState("all");

  const [isChangeAddressApiCall, setIsChangeAddressApiCall] = useState(null);

  const [detail, setDetail] = useState({
    isApiCall: false,
    data: null,
    index: null,
    isModalOpen: false,
  });

  const [searchValue, setSearchValue] = useState("");
  const searchDebounce = useDebounce(searchValue, 1000);

  // edit user
  const [editUserData, setEditUserData] = useState(null);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isEditUserApiCall, setIsEditUserApiCall] = useState(false);
  const [certificateStatusApi, setCertificateStatusApi] = useState(null);

  const [userIdsToDelete, setUserIdsToDelete] = useState([]);
  const [states, setStates] = useState([]);

  // get Item handler
  const getData = async (pageNo, status = search) => {
    const url = BaseURL(
      `users?page=${pageNo}&limit=${limit}&status=${status}&search=${searchDebounce}`
    );

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
      settotal(response?.data?.totalRecords);
    }
  };

  // deleteAccountHandler
  const deleteAccountHandler = async () => {
    const url = BaseURL(`users/delete-users`);
    // userIds
    setIsDeleteApiCall(true);
    const response = await Patch(
      url,
      {
        userIds:
          userIdsToDelete.length > 0 ? userIdsToDelete : [deletingId?.id],
      },
      apiHeader(accessToken)
    );
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      setApiData((p) =>
        p.filter((item) =>
          userIdsToDelete?.length > 0
            ? !userIdsToDelete.includes(item._id)
            : item._id !== deletingId?.id
        )
      );
      setIsAreYouSureModalOpen(false);
      toast.success(
        `Account${userIdsToDelete?.length > 0 ? "s" : ""} deleted successfully.`
      );
      setDeletingId(null);
      setUserIdsToDelete([]);
    }
  };

  // fire on component mount
  useEffect(() => {
    setPageNumber(1);
    getData(1, search);
  }, [searchDebounce]);

  // get single user detail
  const getSingleUserDetail = async (userId, index) => {
    const url = BaseURL(`users/getUserDetails/${userId}`);
    setDetail((p) => ({ ...p, isApiCall: true, index: index }));
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      setDetail((p) => ({
        ...p,
        isApiCall: false,
        index: null,
        data: response?.data?.data,
        isModalOpen: true,
      }));
    } else
      setDetail((p) => ({
        ...p,
        isApiCall: false,
        index: null,
        data: null,
        isModalOpen: false,
      }));
  };

  // handleCertificateGenerate
  const handleCertificateGenerate = async (user, regenerateCertificate) => {
    let url;
    if (user && regenerateCertificate) {
      url = BaseURL(`users/admin/updateTexasUserCertificate/${user?._id}`);
    } else {
      url = BaseURL(`users/admin/generateCertificate`);
    }

    const params = {
      userIds: [user ? user._id : apiData?.map((item) => item._id)].flat(),
    };
    setIsChangeAddressApiCall(user ? user._id : "all");
    const response = await Post(url, params, apiHeader(accessToken));
    setIsChangeAddressApiCall(null);
    if (response !== undefined) {
      const dataCopy = [...apiData];
      if (user && regenerateCertificate) {
        let apiCourseId = response?.data?.data?.course?.course?._id;

        const index = dataCopy.findIndex((item) => item._id === user._id);
        dataCopy[index].progress.find(
          (c) => c.course === apiCourseId
        ).certificatePdf = response?.data?.data?.pdf;
        setApiData(dataCopy);
        window.open(imageUrl(response?.data?.data?.pdf), "_blank");
      }
      if (user && !regenerateCertificate) {
        window.open(
          imageUrl(
            response?.data?.data?.[0]?.progress?.find(
              (c) => c?.status === "pass"
            )?.certificatePdf
          ),
          "_blank"
        );
      } else {
        setPageNumber(1);
        getData(1, search);
      }
      toast.success(
        `${user ? "Certificate" : "Certificates"} generated successfully!`
      );
      // window.location.reload();
    }
  };

  // handleEditUser
  const handleEditUser = async (data) => {
    const url = BaseURL(`users/admin/updateUserProfile/${editUserData._id}`);
    setIsEditUserApiCall(true);
    const response = await Patch(url, data, apiHeader(accessToken));
    setIsEditUserApiCall(false);
    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === editUserData._id);
      dataCopy[index] = response.data?.data;
      setApiData(dataCopy);
      setIsEditUserModalOpen(false);
      setEditUserData(null);
      toast.success(`User updated successfully!`);
    }
  };

  // get all states
  const getAllStates = async () => {
    const url = BaseURL(`state?noPagination=true`);

    const res = await Get(url, accessToken);
    if (res !== undefined) {
      setStates(res?.data?.data);
    }
  };

  // handleCertificateDelivery
  const handleCertificateDelivery = async (user, status) => {
    if (certificateStatusApi !== null) return;
    const params = {
      isTennesseeCertificateToHomelandSecurity: status,
    };
    const url = BaseURL(`users/admin/update/certificate/status/${user._id}`);
    setCertificateStatusApi(user._id);
    const response = await Patch(url, params, apiHeader(accessToken));
    setCertificateStatusApi(null);
    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === user._id);
      dataCopy[index].isTennesseeCertificateToHomelandSecurity = status;
      setApiData(dataCopy);
      toast.success("Certificate status updated successfully!");
    }
  };

  useEffect(() => {
    getAllStates();
  }, []);

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Users</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              {search === "pending-certificates" && apiData?.length > 0 && (
                <button
                  className="update-btn w-160px me-5"
                  style={{
                    color: "#fff",
                  }}
                  disabled={isChangeAddressApiCall === "all"}
                  onClick={() => handleCertificateGenerate(null)}
                >
                  {isChangeAddressApiCall === "all"
                    ? "Please Wait"
                    : "Generate for All"}
                </button>
              )}
              {/* Search */}
              <div
                style={{
                  minWidth: "500px",
                }}
              >
                <input
                  className="add-evidence-modal-input full"
                  type="text"
                  placeholder="Type to search"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e?.target?.value);
                  }}
                  style={{
                    height: "43px",
                  }}
                />
              </div>
            </div>

            <div className="d-flex">
              {/* Filter */}
              <div className="d-flex align-items-center">
                <p className="m-0 me-3 fw-bold">Type:</p>
                <select
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPageNumber(1);
                    getData(1, e.target.value);
                    // HandlepageChange(1, e.target.value, e.target.value);
                  }}
                  className="px-3 py-2 custom-selection"
                >
                  <option value={"all"}>All</option>
                  {states?.map((s, i) => (
                    <option value={s?._id} key={i} className="t-t-c">
                      {s?.state}
                    </option>
                  ))}
                </select>
              </div>

              {userIdsToDelete?.length > 0 && (
                <Button
                  className="ms-2 delete-btn"
                  disabled={isDeleteApiCall}
                  style={{
                    color: "#fff",
                  }}
                  onClick={() => {
                    setIsAreYouSureModalOpen(true);
                    setDeletingId(null);
                  }}
                >
                  {isDeleteApiCall ? "Please Wait..." : "Delete Users"}
                </Button>
              )}
            </div>
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th> - </th>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Add On</th>
                      <th>State</th>
                      <th>Member Since</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiData?.map((item, index) => {
                      let course = item?.progress?.find(
                        (f) => f.status === "pass"
                      );
                      return (
                        <tr key={item?._id}>
                          <td>
                            <input
                              type="checkbox"
                              style={{
                                position: "initial",
                              }}
                              checked={userIdsToDelete.includes(item?._id)}
                              onChange={() => {
                                if (userIdsToDelete.includes(item?._id)) {
                                  setUserIdsToDelete((p) =>
                                    p.filter((id) => id !== item?._id)
                                  );
                                } else {
                                  setUserIdsToDelete((p) => [...p, item?._id]);
                                }
                              }}
                            />
                          </td>
                          <td>{++index}</td>
                          <td className="t-t-c">
                            {[item?.firstName, item?.lastName].join(" ")}
                          </td>
                          <td>{item?.email}</td>
                          <td>
                            {
                              item?.sendTennesseeCertificateToHomelandSecurity ? (
                                <input
                                  type="checkbox"
                                  className="form-check-input__custom"
                                  style={{
                                    position: "initial",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                  checked={
                                    item?.isTennesseeCertificateToHomelandSecurity
                                  }
                                  disabled={certificateStatusApi === item?._id}
                                  onChange={(e) =>
                                    handleCertificateDelivery(
                                      item,
                                      e.target.checked
                                    )
                                  }
                                />
                              ) : (
                                "-"
                              )
                              //   <input
                              //   type="checkbox"
                              //   className="form-check-input__custom"
                              //   style={{
                              //     position: "initial",
                              //     width: "20px",
                              //     height: "20px",
                              //   }}
                              //   checked={item?.sendTennesseeCertificateToHomelandSecurity}
                              //   disabled={true}
                              // />
                            }
                          </td>
                          <td className="t-t-c">{item?.state?.state}</td>
                          <td>{moment(item?.createdAt).format("lll")}</td>

                          <td>
                            <ul className="d-flex">
                              <li className="page-item align-self-end me-2">
                                <Button
                                  onClick={() => {
                                    setIsEditUserModalOpen(true);
                                    setEditUserData(item);
                                  }}
                                  className="update-btn"
                                >
                                  Edit
                                </Button>
                              </li>
                              {/* Detail */}
                              <li className="page-item align-self-end me-2">
                                <Button
                                  onClick={() => {
                                    getSingleUserDetail(item?._id, --index);
                                    setDetail((p) => ({ ...p, index: index }));
                                  }}
                                  className="update-btn w-160px"
                                  disabled={detail?.index === index - 1}
                                >
                                  {detail?.index === index - 1
                                    ? "Please Wait..."
                                    : "View Detail"}
                                </Button>
                              </li>

                              {/* Delete Account */}
                              {!item?.isAccountDeleted && (
                                <li
                                  title="Delete Account"
                                  className="page-item align-self-end ml-4"
                                  onClick={() => {
                                    setDeletingId({
                                      id: item?._id,
                                      isActive: !item?.isActive,
                                    });
                                    setIsAreYouSureModalOpen(true);
                                  }}
                                >
                                  <Button className="delete-btn">Delete</Button>
                                </li>
                              )}

                              {course?.status === "pass" && (
                                <li
                                  className="page-item align-self-end ms-2"
                                  onClick={() => {
                                    if (
                                      course?.certificatePdf &&
                                      item?.state?.state?.toLowerCase() ===
                                        "texas"
                                    ) {
                                      const regenerateCertificate = true;
                                      handleCertificateGenerate(
                                        item,
                                        regenerateCertificate
                                      );
                                    } else {
                                      handleCertificateGenerate(item);
                                    }
                                  }}
                                >
                                  <Button
                                    className="delete-btn"
                                    style={{
                                      width: "214px",
                                    }}
                                    disabled={
                                      isChangeAddressApiCall === "all" ||
                                      isChangeAddressApiCall === item?._id
                                    }
                                  >
                                    {isChangeAddressApiCall === "all" ||
                                    isChangeAddressApiCall === item?._id
                                      ? "Please Wait..."
                                      : course?.status === "pass" ||
                                        course?.certificatePdf
                                      ? "Re-generate Certificate"
                                      : "Generate Certificate"}
                                  </Button>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="page">
            {total > limit && (
              <Pagination
                totalPages={Math.ceil(total / limit)}
                setCurrentPage={(p) => {
                  setPageNumber(p);
                  getData(p, search);
                }}
                currentPage={pageNumber}
              />
            )}
          </div>
        </div>

        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={`Do you really want to delete ${
              userIdsToDelete?.length > 0
                ? `${userIdsToDelete?.length} users`
                : "this user"
            }? This action cannot be undone.`}
            handleYes={deleteAccountHandler}
            isLoading={isDeleteApiCall}
          />
        )}

        {detail?.isModalOpen && (
          <UserDetailModal
            open={detail?.isModalOpen}
            setOpen={() => setDetail((p) => ({ ...p, isModalOpen: false }))}
            data={detail?.data}
          />
        )}

        {isEditUserModalOpen && (
          <EditUserInfoModal
            data={editUserData}
            isLoading={isEditUserApiCall}
            open={isEditUserModalOpen}
            setOpen={setIsEditUserModalOpen}
            onClick={handleEditUser}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default UsersScreen;
