import React, { useState } from "react";
import { toast } from "react-toastify";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { formRegEx, formRegExReplacer, numberRegEx } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";

const AddAndEditStateModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
}) => {
  // states
  const [state, setState] = useState(data?.state || "");
  const [securityQuestions, setSecurityQuestions] = useState(
    data?.securityQuestions || false
  );
  // const [enquiryForm, setEnquiryForm] = useState(data?.enquiryForm || false);
  const [passingCriteria, setPassingCriteria] = useState(
    data?.passingCriteria || ""
  );
  const [headQuarter, setHeadQuarter] = useState(data?.headQuarter || "");
  const [hours, setHours] = useState(data?.hours || "");
  const [minutes, setMinutes] = useState(data?.minutes || "");

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      state,
      securityQuestions,
      // enquiryForm,
      passingCriteria,
      headQuarter,
      hours,
      minutes,
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} State
        </span>

        {/* State */}
        <div>
          <label>State Name</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="State Name Here"
            value={state}
            onChange={(e) => {
              setState(e.target.value);
            }}
            disabled={data}
          />
        </div>

        <div className="mt-3">
          <label>Passing Criteria (in %)</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            max="100"
            placeholder="Passing Criteria (in %)"
            value={passingCriteria}
            onChange={(e) => {
              setPassingCriteria(e.target.value);
            }}
          />
        </div>
        <div className="mt-3">
          <label>Headquarter</label>
          <textarea
            className="add-evidence-modal-input full h-auto"
            type="text"
            placeholder={`${state} Headquarter Here`}
            value={headQuarter}
            onChange={(e) => {
              setHeadQuarter(e.target.value);
            }}
            rows={3}
          />
        </div>
        <div className="mt-3">
          <label>Duration (Hours)</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder={`Duration (Hours) Here`}
            value={hours}
            onChange={(e) => {
              setHours(e.target.value.replace(numberRegEx, ""));
            }}
            rows={3}
          />
        </div>
        <div className="mt-3">
          <label>Duration (Minutes)</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder={`Duration (Minutes) Here`}
            value={minutes}
            onChange={(e) => {
              setMinutes(e.target.value?.replace(numberRegEx, ""));
            }}
            rows={3}
          />
        </div>

        {/* securityQuestions */}
        <div className="mt-3">
          <label>Security Questions</label>
          <FormControl component="fieldset" className="d-b">
            <RadioGroup
              row
              aria-label="status"
              name="controlled-radio-buttons-group"
              value={securityQuestions}
              onChange={(e) => setSecurityQuestions(e?.target?.value)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Required"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Not required"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {/* enquiryForm */}
        {/* <div className="mt-3">
          <label>Enquiry Form</label>
          <FormControl component="fieldset" className="d-b">
            <RadioGroup
              row
              aria-label="status"
              name="controlled-radio-buttons-group"
              value={enquiryForm}
              onChange={(e) => setEnquiryForm(e?.target?.value)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Required"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Not required"
              />
            </RadioGroup>
          </FormControl>
        </div> */}

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditStateModal;
