import { Skeleton } from "@material-ui/lab";
import React from "react";

const TableRowsLoader = () => {
  // const cardRanges = [1, 2, 3, 4, 5, 6, 8, 9, 10];

  return (
    <>
      <div className="col-md-12">
        <h3 className>Loading Contents</h3>
      </div>
      {Array(11)
        .fill(0)
        ?.map((ele) => {
          return (
            <div className="col-md-12 ">
              <Skeleton
                animation="wave"
                style={{
                  borderRadius: "6px",
                  height: "70px",
                  width: "100%",
                  color: "gray",
                }}
              />
            </div>
          );
        })}
    </>
  );
};

export default TableRowsLoader;
