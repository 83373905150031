import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { BaseURL, apiHeader } from "../../Config/apiUrl";
import ScreenBoiler from "../../components/ScreenBoiler";
import { toast } from "react-toastify";

const AppConfig = () => {
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const [loginId, setLoginId] = React.useState("");
  const [transactionKey, setTransactionKey] = React.useState("");
  const [isGetting, setIsGetting] = React.useState(false);
  const [data, setData] = useState({
    loginId: "",
    transactionKey: "",
  });

  // getData
  const getData = async () => {
    const url = BaseURL(`app-config`);

    setIsGetting(true);
    const res = await Get(url, accessToken);
    setIsGetting(false);

    if (res) {
      setLoginId(res?.data?.data?.loginId);
      setTransactionKey(res?.data?.data?.transactionKey);
      setData({
        loginId: res?.data?.data?.loginId,
        transactionKey: res?.data?.data?.transactionKey,
      });
    }
  };

  // handleUpdateKeys
  const handleUpdateKeys = async () => {
    const params = {
      loginId: loginId?.trim(),
      transactionKey: transactionKey?.trim(),
    };

    if(loginId === "" || transactionKey === ""){
      return toast.error("All fields are required.")
    }

    if (loginId === data?.loginId && transactionKey === data?.transactionKey) {
      return toast.error(
        "It looks like you have'nt changed anything. Please change the keys and then press update button"
      );
    }

    const url = BaseURL("app-config/update");

    setIsGetting(true);
    const res = await Patch(url, params, apiHeader(accessToken));
    setIsGetting(false);

    if (res) {
      toast.success("Keys updated successfully.");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>App Settings</h1>
        </div>
      </section>
      <div id="root">
        <Row>
          <Col>
            <div className="mt-3">
              <label>Login ID</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Enter Authorize.net login Id"
                value={loginId}
                onChange={(e) => {
                  setLoginId(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col>
            <div className="mt-3">
              <label>Transaction Key</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Enter Authorize.net Transaction Key"
                value={transactionKey}
                onChange={(e) => {
                  setTransactionKey(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <li
            className="page-item align-self-end ml-4 mt-3 d-flex justify-content-end"
            onClick={handleUpdateKeys}
            disabled={isGetting}
          >
            <Button className="delete-btn">
              {isGetting ? "Please Wait..." : "Update Keys"}
            </Button>
          </li>
        </Row>
      </div>
    </ScreenBoiler>
  );
};

export default AppConfig;
