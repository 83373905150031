import "../assets/Styles/forgot.css";
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

export default function ModalSkeleton({ open, setOpen, children, type, overflow }) {
  const handleClose = () => setOpen(false);

  const customStyle = {
    position: "absolute",
    left: "0",
    width: "calc(100% - 2rem)",
    margin: "auto",
    right: "0",
    maxWidth: type === "big" ? 1000 : 500,
    bgcolor: "background.paper",
    border: "2px solid #00C463",
    boxShadow: 24,
    borderRadius: "10px",
    maxHeight: "80vh",
    height:"auto",
    p: 4,
    overflowY: "scroll !important",
    top:'50%',
    transform:'translateY(-50%)',
  };
  const parentStyle = {
    padding:'1rem',
  }

  return (
    <div className={`${type === "big" && "big_modal_new"} `} style={parentStyle}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={customStyle} className={`modal-scroll over-floe-unset-x ${overflow == true && 'modal-overflow-scroll-z'}`}>
            {children}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
