import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AiFillDashboard, AiFillSafetyCertificate } from "react-icons/ai";
import { BiChevronLeft } from "react-icons/bi";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { BiChevronRight, BiMessageAltCheck } from "react-icons/bi";
import { AiFillQuestionCircle, AiOutlineUser } from "react-icons/ai";
import {
  MdOutlineLogout,
  MdOutlineRealEstateAgent,
  MdPayment,
  MdPlayLesson,
  MdSecurity,
  MdOutlineFolderSpecial,
} from "react-icons/md";
import {
  MdImportContacts,
  MdOutlineSettingsSystemDaydream,
} from "react-icons/md";
import { IoIosSettings, IoMdPricetags } from "react-icons/io";
import { SiCoursera } from "react-icons/si";
import { signOutRequest, ToggleDrawer } from "../redux/auth/authSlice";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appLogo } from "../constant/imagePath";
import { VscFeedback } from "react-icons/vsc";
import { FaLock, FaQuora } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";
import Tooltip from "@mui/material/Tooltip";

const SideNavbar = ({}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  // const [open, setOpen] = useState(false);
  const open = useSelector((state) => state.authReducer?.isOpen);
  const [selected, setSelected] = useState("/");

  function SidebarItem({ route, text, position, icon }) {
    return (
      <li
        className={`nav-item ${position == route && "activeSideItem"} ${
          open && "isOpen"
        }`}
        onClick={() => {
          dispatch(ToggleDrawer(false));
        }}
      >
        <Tooltip placement="right" title={text}>
          <Link to={route} className="mouse-cursor sideBarNavLink">
            {icon}
            {open && <span>{text}</span>}
          </Link>
        </Tooltip>
      </li>
    );
  }

  const handleDrawerToggle = async () => {
    await dispatch(ToggleDrawer(!open));
    // setOpen(!open);
  };
  useEffect(() => {
    const pathName = location?.pathname;
    setSelected(pathName);
  }, [location]);

  function logoutUser() {
    dispatch(signOutRequest());
    navigation("/login");
  }

  return (
    <>
      <ul className="navbar-nav customSideBar" id="accordionSidebar">
        <div className="sideBarHeader">
          {open ? (
            <>
              <div className="sideBarLogo" onClick={() => {}}>
                <img src={appLogo} alt="" className="image-fluid" />
                {/* 🤗🙂🤗🙂 */}
              </div>
              <BiChevronLeft
                size={25}
                color="#00C463"
                onClick={() => {
                  handleDrawerToggle();
                }}
              />
            </>
          ) : (
            <BiChevronRight
              size={25}
              color="#00C463"
              onClick={() => {
                handleDrawerToggle();
              }}
            />
          )}
        </div>

        <hr className="sideBarDivider my-0" />

        {/* <SidebarItem
          text={"Dashboard"}
          route="/"
          position={selected}
          icon={<FiUsers size={25} color="#00C463" />}
        /> */}
        <SidebarItem
          text={"Dashboard"}
          route="/"
          position={selected}
          icon={<AiFillDashboard size={25} color="#00C463" />}
        />
        {/* States */}
        <SidebarItem
          text={"States"}
          route="/state"
          position={selected}
          icon={<MdOutlineRealEstateAgent size={25} color="#00C463" />}
        />

        {/* Packages */}
        <SidebarItem
          text={"Packages"}
          route="/packages"
          position={selected}
          icon={<MdPayment size={25} color="#00C463" />}
        />

        {/* Packages */}
        <SidebarItem
          text={"Courses"}
          route="/courses"
          position={selected}
          icon={<SiCoursera size={25} color="#00C463" />}
        />

        {/* lessons */}
        <SidebarItem
          text={"Lessons"}
          route="/lessons"
          position={selected}
          icon={<MdPlayLesson size={25} color="#00C463" />}
        />

        {/* Question */}
        <SidebarItem
          text={"Question"}
          route="/question"
          position={selected}
          icon={<AiFillQuestionCircle size={25} color="#00C463" />}
        />

        {/* Exam */}
        <SidebarItem
          text={"Exam"}
          route="/exam"
          position={selected}
          icon={<BsFillJournalBookmarkFill size={25} color="#00C463" />}
        />

        {/* security-questions */}
        <SidebarItem
          text={"Security Qs"}
          route="/security-questions"
          position={selected}
          icon={<MdSecurity size={25} color="#00C463" />}
        />
        {/* after-exam-questions */}
        <SidebarItem
          text={"Other Qs"}
          route="/after-exam-questions"
          position={selected}
          icon={<MdSecurity size={25} color="#00C463" />}
        />
        {/* /evaluationQs */}
        <SidebarItem
          text={"Evaluation Qs"}
          route="/evaluationQs"
          position={selected}
          icon={<MdSecurity size={25} color="#00C463" />}
        />

        {/* security-questions */}
        <SidebarItem
          text={"Users"}
          route="/users"
          position={selected}
          icon={<AiOutlineUser size={25} color="#00C463" />}
        />

        {/* security-questions */}
        <SidebarItem
          text={"User Requests"}
          route="/requests"
          position={selected}
          icon={<BiMessageAltCheck size={25} color="#00C463" />}
        />

        {/* contact Messages */}
        <SidebarItem
          text={"Messages"}
          route="/contact"
          position={selected}
          icon={<MdImportContacts size={25} color="#00C463" />}
        />

        {/* testimonial */}
        <SidebarItem
          text={"Testimonial"}
          route="/testimonial"
          position={selected}
          icon={<VscFeedback size={25} color="#00C463" />}
        />
        {/* faq */}
        <SidebarItem
          text={"FAQs"}
          route="/faq"
          position={selected}
          icon={<FaQuora size={25} color="#00C463" />}
        />

        {/* reports */}
        <SidebarItem
          text={"Reports"}
          route="/reports"
          position={selected}
          icon={<SiMicrosoftexcel size={25} color="#00C463" />}
        />

        {/* cms */}
        <SidebarItem
          text={"CMS"}
          route="/cms"
          position={selected}
          icon={<MdOutlineSettingsSystemDaydream size={25} color="#00C463" />}
        />
        <SidebarItem
          text={"Coupon"}
          route="/coupon"
          position={selected}
          icon={<IoMdPricetags size={25} color="#00C463" />}
        />
        <SidebarItem
          text={"Certificate Qty"}
          route="/certificate"
          position={selected}
          icon={<AiFillSafetyCertificate size={25} color="#00C463" />}
        />
        <SidebarItem
          text={"Change Password"}
          route="/update-password"
          position={selected}
          icon={<FaLock size={25} color="#00C463" />}
        />
        <SidebarItem
          text={"App Settings"}
          route="/app-config"
          position={selected}
          icon={<IoIosSettings size={25} color="#00C463" />}
        />

        {/* <SidebarItem
          text={"Users"}
          route="/allusers"
          position={selected}
          icon={<FiUsers size={25} color="#00C463" />}
        />

        <SidebarItem
          text={"Update Password"}
          route="/updatepassword"
          position={selected}
          icon={
            <RiLockPasswordLine src={refreshIcon} size={25} color="#00C463" />
          }
        /> */}

        <li className={`nav-item c-p  ${open && "isOpen"}`}>
          <div
            className="mouse-cursor sideBarNavLink"
            onClick={() => {
              logoutUser();
            }}
          >
            <MdOutlineLogout size={25} color="#00C463" />
            {open && <span>Logout</span>}
          </div>
        </li>
      </ul>
    </>
  );
};

export default SideNavbar;
