import React, { useState } from "react";
import { toast } from "react-toastify";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { formRegEx, formRegExReplacer } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";

const GenerateReportModal = ({ open, setOpen, isLoading, handleSubmit }) => {
  // states
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filename, setFilename] = useState("");

  const [reportType, setReportType] = useState("registration");

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      filename,
      reportType,
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          Generate Report
        </span>

        {/* Report */}
        <div>
          <label>Report Name</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Set Report Name"
            value={filename}
            onChange={(e) => {
              setFilename(e.target.value);
            }}
          />
        </div>

        {/* Start Date */}
        <div className="mt-3">
          <label>Start Date</label>
          <input
            className="add-evidence-modal-input full"
            type="date"
            placeholder="Start Date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>

        {/* End Date */}
        <div className="mt-3">
          <label>End Date</label>
          <input
            className="add-evidence-modal-input full"
            type="date"
            placeholder="End Date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>

        {/* reportType */}
        <div className="mt-3">
          <label>Report Type</label>
          <FormControl component="fieldset" className="d-b">
            <RadioGroup
              row
              aria-label="status"
              name="controlled-radio-buttons-group"
              value={reportType}
              onChange={(e) => setReportType(e?.target?.value)}
            >
              <FormControlLabel
                value={"result"}
                control={<Radio />}
                label="Result"
              />
              <FormControlLabel
                value={"registration"}
                control={<Radio />}
                label="Registrations"
              />
              <FormControlLabel
                value={"dmv"}
                control={<Radio />}
                label="Dmv"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default GenerateReportModal;
