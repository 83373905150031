import React, { useState } from "react";
import { toast } from "react-toastify";

import { formRegEx, formRegExReplacer, imageUrl } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";
import { green } from "@material-ui/core/colors";

const AddAndEditWhatOurStudentsSayModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
}) => {
  const [name, setName] = useState(data?.name || "");
  const [description, setDescription] = useState(data?.description || "");
  const [location, setLocation] = useState(data?.location || "");

  // IMAGE / vIDEO
  const [selectedImage, setSelectedImage] = useState(data?.image || undefined);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      name,
      description,
      location,
      ...(selectedImageFile && { image: selectedImageFile }),
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required.`
        );

    // validateImage
    if (!selectedImageFile && !selectedImage)
      return toast.warn(`Image is required.`);

    handleSubmit(params);
  };

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onload = (event) => {
      setSelectedImage(event.target.result);

      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        // setVideoDuration(millisToMinutesAndSeconds(media.duration * 1000));
      };
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Testimonial
        </span>

        {/* Name */}
        <div>
          <label>User Name</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Name Here"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        {/* Location */}
        <div className="mt-3">
          <label>User Location</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Location Here"
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
            }}
          />
        </div>

        {/* Description */}
        <div className="mt-3">
          <label>Description</label>
          <textarea
            className="form-control"
            rows={5}
            type="text"
            placeholder="Location Here"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>

        {/* Video */}
        <div class="form-group" className="seo__imagePicker mt-3">
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            name="uploadfile"
            id="img"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event);
            }}
          />
          <label for="exampleInputEmail1">Select Image</label>
          <label
            for="img"
            className="btn btn-primary btn-lg btn-block"
            style={{
              backgroundColor: green[500],
            }}
            onChange={(e) => {
              // console.log(e.target.files[0]);
            }}
          >
            {selectedImage ? "Change selection" : "Pick an Image"}
          </label>
        </div>

        {/* Shoe Image */}
        {selectedImage && (
          <div className="d-flex align-items-center justify-content-center w-100">
            <img
              src={selectedImageFile ? selectedImage : imageUrl(selectedImage)}
              alt="selectedImage"
              className="w-50 mt-3"
            />
          </div>
        )}

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditWhatOurStudentsSayModal;
