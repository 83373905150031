import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

export default function PopOverUserTopicDetail({ data }) {
  data.sort(function (a, b) {
    // Convert the "createdAt" strings to Moment.js objects
    var dateA = moment(a.createdAt);
    var dateB = moment(b.createdAt);

    // Compare the Moment.js objects
    if (dateA.isBefore(dateB)) {
      return -1;
    } else if (dateA.isAfter(dateB)) {
      return 1;
    } else {
      return 0;
    }
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className="update-btn w-160px"
      >
        View Detail
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        className={"popover-menu-xx"}
      >
        <Box sx={{ width: 600 }}>
          <Row className="p-5">
            <p className="detailHeading2 mb-4">Topic Info:</p>
            {data?.map((e, i) => (
              <Col md={12} className="topicDetailItem" key={i}>
                <div className={"listConatiner"}>
                  <p>
                    Topic:{" "}
                    <span className={"historyListRight t-t-c"}>
                      {e?.topic?.topicName}
                    </span>
                  </p>

                  <div className="d-flex justify-content-between">
                    <p
                      style={{
                        fontsize: "14px",
                      }}
                    >
                      <b>Start Date:</b>{" "}
                      {e?.createdOn
                        ? moment(e?.createdOn).format("lll")
                        : "Not started yet."}
                    </p>
                    <p
                      style={{
                        fontsize: "14px",
                      }}
                    >
                      <b>End Date:</b>{" "}
                      {e?.endDate ? moment(e?.endDate).format("lll") : "-"}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Box>
      </Popover>
    </div>
  );
}
