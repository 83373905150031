import React, { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { formRegEx, formRegExReplacer } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";
import moment from "moment";

const AddAndEditCoupanModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
  states: statesData,
}) => {
  // states
  const [code, setCode] = useState(data?.code || "");
  const [expire, setExpire] = useState(
    data?.expire ? moment(data?.expire).format("YYYY-MM-DD") : false
  );
  const [state, setState] = useState(data?.states || "");

  const [discount, setDiscount] = useState(data?.discount || "");
  const [maxUsers, setMaxUsers] = useState(data?.maxUsers || "");
  const [isActive, setIsActive] = useState(data?.isActive || "");

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      code,
      expire: new Date(expire),
      discount,
      maxUsers,
      isActive,
      states: state.map((e) => e?._id),
    };

    if (isNaN(discount)) return toast.warn("Discount should be a number.");
    if (isNaN(maxUsers)) return toast.warn("Max Users should be a number.");
    if (discount > 100 || discount <= 0)
      return toast.warn("Discount should be between 1 to 100.");

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Coupon
        </span>

        {/* Code */}
        <div>
          <label>Coupon Code</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Coupon Code Here"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
        </div>

        <div className="mt-3">
          <label>Select States</label>
          <Select
            defaultValue={state}
            isMulti
            options={statesData}
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={(option) => option.state}
            getOptionValue={(option) => option._id}
            onChange={(e) => {
              setState(e);
            }}
          />
        </div>

        <div className="mt-3">
          <label>Expire Date</label>
          <input
            className="add-evidence-modal-input full"
            type="date"
            placeholder="Expir Date Here"
            value={expire}
            onChange={(e) => {
              setExpire(e.target.value);
            }}
          />
        </div>

        <div className="mt-3">
          <label>Discount (in %)</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            max="100"
            maxLength={3}
            placeholder="Discount (in %)"
            value={discount}
            onChange={(e) => {
              setDiscount(e.target.value);
            }}
          />
        </div>

        <div className="mt-3">
          <label>Max Users</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            max="100"
            placeholder="Max Users"
            value={maxUsers}
            onChange={(e) => {
              setMaxUsers(e.target.value);
            }}
          />
        </div>

        {/* active */}
        <div className="mt-3">
          <label>Active</label>
          <FormControl component="fieldset" className="d-b">
            <RadioGroup
              row
              aria-label="status"
              name="controlled-radio-buttons-group"
              value={isActive}
              onChange={(e) => setIsActive(e?.target?.value)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="In Active"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditCoupanModal;
