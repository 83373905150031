import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import moment from "moment";

import ShowMoreShowLessText from "./ShowMoreShowLessText";
import ExpandWrapper from "./ExpandWrapper";

export default function PopOverUser({
  data,
  afterExamQuestions,
  evaluations,
  isEvaluated,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className="update-btn w-160px"
      >
        View Detail
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        className={"popover-menu-xx"}
      >
        <Box sx={{ width: 600 }}>
          {/* Exam Info */}
          <>
            <p className="detailHeading2">Exam Info:</p>
            <div className={"listConatinerSimple"}>
              <p>
                Exam Name:{" "}
                <span className={"historyListRight t-t-c"}>
                  {data?.examId?.examName}
                </span>
              </p>
            </div>
            <div className={"listConatinerSimple"}>
              <p>
                Duration:{" "}
                <span className={"historyListRight t-t-c"}>
                  {data?.examId?.duration} mins
                </span>
              </p>
            </div>
          </>

          <hr />
          {/* Attempted Exam Info */}
          {data?.attemptedExam && (
            <>
              <p className="detailHeading2">Attempted Exam Info:</p>
              <div className={"listConatinerSimple"}>
                <p>
                  Course:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {data?.attemptedExam?.course?.courseName}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Exam:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {data?.attemptedExam?.exam?.examName}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Total Points:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {parseFloat(data?.attemptedExam?.totalPoints).toFixed()}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Obtained Points:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {parseFloat(data?.attemptedExam?.obtainedPoints).toFixed()}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Percentage:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {parseFloat(data?.attemptedExam?.percentage).toFixed(2)}%
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Exam Start Time:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {moment(data?.attemptedExam?.startTime).format("lll")}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Status:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {data?.attemptedExam?.status}
                  </span>
                </p>
              </div>
              <hr />
            </>
          )}
          {/* exam Detail Info */}
          {data?.examDetail?.length > 0 && (
            <>
              <p className="detailHeading2">Exam Detail:</p>
              <ExpandWrapper _space={'2.5rem'}>
                {data?.examDetail?.map((item, i) => {
                  return (
                    <div className={"listConatinerSimple mb-3"} key={i}>
                      <p>
                        Question {++i}:{" "}
                        <span className={"historyListRight t-t-c"}>
                          <ShowMoreShowLessText
                            text={item?.questionId?.question}
                            visibility={80}
                          />
                        </span>
                      </p>
                      {/* Options */}
                      {item?.questionId?.answers?.map((ans, ansInd) => (
                        <div className="option-text-container">
                          <span>{++ansInd})</span>{" "}
                          <span
                            className={`option-text t-t-c ${
                              item?.selectedAnswer === ans &&
                              item?.isCorrectAnswer &&
                              "correct-option"
                            }
                        ${
                          item?.selectedAnswer === ans &&
                          !item?.isCorrectAnswer &&
                          "wrong-option"
                        }
                        `}
                            key={ansInd}
                          >
                            <ShowMoreShowLessText text={ans} visibility={60} />
                          </span>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </ExpandWrapper>
            </>
          )}

          {/* Evaluation */}
          {isEvaluated && (
            <>
              <p className="detailHeading2">
                Traffic Voilator Course Evaluation
              </p>
              <div className={"listConatinerSimple"}>
                <p>
                  First Name:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {evaluations?.firstName}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Last Name:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {evaluations?.lastName}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Course Completion Date:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {moment(evaluations?.courseCompletionDate).format("ll")}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  School Name:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {evaluations?.schoolName}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  School License Number:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {evaluations?.schoolLicenseNumber}
                  </span>
                </p>
              </div>
              <p className="detailHeading2">Q&A:</p>
              <ExpandWrapper _space={'2.5rem'}>
                {evaluations?.evaluationData?.map((item, i) => {
                  return (
                    <div className={"listConatinerSimple mb-3"} key={i}>
                      <p>
                        Question {++i}:{" "}
                        <span className={"historyListRight t-t-c"}>
                          <ShowMoreShowLessText
                            text={item?.evaluationId?.question}
                            visibility={80}
                          />
                        </span>
                      </p>
                      {/* Options */}
                      {item?.evaluationId?.answers?.map((ans, ansInd) => (
                        <div className="option-text-container">
                          <span>{++ansInd})</span>{" "}
                          <span
                            className={`option-text t-t-c ${
                              item?.answer === ans && "correct-option"
                            }
                        
                        `}
                            key={ansInd}
                          >
                            <ShowMoreShowLessText text={ans} visibility={60} />
                          </span>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </ExpandWrapper>
            </>
          )}

          {/* afterExamQuestions */}
          {afterExamQuestions?.length > 0 && (
            <>
              <p className="detailHeading2">User Feedback:</p>
              {afterExamQuestions?.map((item, i) => {
                return (
                  <div className={"listConatinerSimple mb-3"} key={i}>
                    <p>
                      Question {++i}:{" "}
                      <span className={"historyListRight t-t-c"}>
                        <ShowMoreShowLessText
                          text={item?.question}
                          visibility={80}
                        />
                      </span>
                    </p>
                    {/* Ans */}
                    <div className="option-text-container">
                      <span>{`Ans)`}</span>{" "}
                      <span className={`option-text t-t-c`}>
                        <ShowMoreShowLessText
                          text={item?.answer}
                          visibility={60}
                        />
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </Box>
      </Popover>
    </div>
  );
}
