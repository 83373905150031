import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export default function QuestionAccordian({ data, i }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === `panel${i}`}
        onChange={handleChange(`panel${i}`)}
        className="mb-1"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {data.question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Answers Map */}
          <h5>Options</h5>
          <div className="d-flex flex-wrap">
            {data?.answers?.map((e, i) => (
              <ListItem disablePadding className="my-3 me-3 w-25" key={i}>
                <ListItemButton>
                  <ListItemIcon>
                    <QuestionAnswerIcon />
                  </ListItemIcon>
                  <ListItemText primary={e} className="text-gray-new2" />
                </ListItemButton>
              </ListItem>
            ))}
          </div>
          {/* correct answer */}
          <h5>Correct Option</h5>
          <ListItem disablePadding className="my-3 me-3 w-25">
            <ListItemButton>
              <ListItemIcon>
                <QuestionAnswerIcon />
              </ListItemIcon>
              <ListItemText primary={data?.correctAnswer[0]} />
            </ListItemButton>
          </ListItem>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
