import React, { useState } from "react";
import { toast } from "react-toastify";

import ModalSkeleton from "./ModalSkeleton";

const ContactMessageModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
}) => {
  // states
  const [message, setMessage] = useState("");

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      message,
    };

    // validate
    if (message?.trim() === "") return toast.error("Please enter your message");

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          Reply
        </span>

        {/* State */}
        <div>
          <label>
            Type your message (This will be sent to the user's email)
          </label>
          <textarea
            className="w-100 form-control mt-2"
            type="text"
            placeholder="Message here"
            value={message}
            rows={5}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </div>

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default ContactMessageModal;
