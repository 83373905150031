import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import ReactQuill from "react-quill"; // ES6

import {
  formRegEx,
  formRegExReplacer,
  imageUrl,
  numberRegEx,
} from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";
import AddImageComponent from "./AddImageComponent";
import CircularLoader from "./CircularLoader";
import { CardMedia } from "@mui/material";
import { green } from "@mui/material/colors";

const AddAndEditTopicModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
  questionArray,
  lesson,
  videoProgress,
  setApiData,
}) => {
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: ["small", false, "large", "huge"] }, { color: [] }],
          ["image", "video", "link"],
        ],
      },
    }),
    []
  );

  // states
  const [topicName, setTopicName] = useState(data?.topicName || "");
  const [quizQuestions, setQuizQuestions] = useState(
    data?.quiz?.questions || ""
  );
  const [quizName, setQuizName] = useState(data?.quiz?.quizName || "");
  const [quizDuration, setQuizDuration] = useState(data?.quiz?.duration || "");
  const [description, setDescription] = useState(data?.description || "");
  const [passingCriteria, setPassingCriteria] = useState(
    data?.quiz?.passingCriteria || ""
  );
  const [hours, setHours] = useState(data?.hours || "");
  const [minutes, setMinutes] = useState(data?.minutes || "");

  const [isLoadingQuill, setIsLoadingQuill] = useState(
    data?.descriptionFile ? true : false
  );

  // Pdfs
  const [pdfs, setPdfs] = useState(
    data?.pdf?.length > 0 ? data?.pdf : [{}, {}]
  );
  const [oldPdf, setOldPdf] = useState(data?.pdf ? [...data?.pdf] : []);

  // Images
  const [images, setImages] = useState(
    data?.images?.length > 0 ? data?.images : [{}, {}]
  );
  const [oldImages, setOldImages] = useState(
    data?.images ? [...data?.images] : []
  );

  // vIDEO
  const [selectedImage, setSelectedImage] = useState(data?.video || undefined);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  useEffect(() => {
    if (data) {
      if (data?.descriptionFile) {
        let descriptionFileUrl = imageUrl(data?.descriptionFile);
        // extract text from file
        fetch(descriptionFileUrl)
          .then((r) => r.text())
          .then((text) => {
            setDescription(text);
          })
          .finally(() => {
            setIsLoadingQuill(false);
          });
      } else {
        setDescription(data?.description);
      }
    }
  }, [data]);

  // add pdf
  const handlePdfsUpdate = (pdfData, key) => {
    setPdfs(pdfData);

    if (key != null && typeof pdfData != "object")
      setApiData((p) => {
        let copy = { ...p };
        const index = copy.topics.findIndex((e) => e?._id === data?._id);
        copy.topics[index].pdf = copy.topics[index]?.pdf?.filter(
          (e) => e != key
        );
        return copy;
      });
  };

  // add image
  const handleImagesUpdate = (imgData, key) => {
    setImages(imgData);

    if (key != null && typeof imgData != "object")
      setApiData((p) => {
        let copy = { ...p };
        const index = copy.topics.findIndex((e) => e?._id === data?._id);
        copy.topics[index].images = copy.topics[index]?.images?.filter(
          (e) => e != key
        );
        return copy;
      });
  };

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    // images
    let _images = [];
    images.forEach((e, i) => {
      if (e.name !== undefined) {
        _images.push(e);
      }
    });

    // check size of description in mb
    const descriptionInBytes = new Blob([description]).size;
    const descriptionInMb = descriptionInBytes / 1000000.0;
    console.log("descriptionInMb ", descriptionInMb);

    // create .txt file of description
    const descriptionFile = new File([description], "description.txt", {
      type: "text/plain",
    });

    console.log("descriptionFile ", descriptionFile);

    const params = {
      topicName,
      description,
      hours,
      minutes,
    };

    if (isNaN(hours) || isNaN(minutes))
      return toast.warn("Please enter valid number in hours and minutes field");
    if (minutes <= 0 && hours == 0)
      return toast.warn("Topic duration cannot be 0 minutes and 0 hours");

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    params.quizName = quizName;
    params.quizDuration = quizDuration;

    // handle Quiz
    if (quizName.trim() !== "" && quizQuestions.length === 0)
      return toast.warn("Please add atleast one question");
    if (quizName.trim() !== "" && quizDuration === "")
      return toast.warn("Please add quiz duration");
    if (quizName.trim() !== "" && passingCriteria === "")
      return toast.warn("Please specify passing criteria");

    // pdfs
    let _pdfs = [];
    pdfs.forEach((e, i) => {
      if (e.name !== undefined) {
        _pdfs.push(e);
      }
    });

    let finalParams = {
      topicName,
      video: "remaining",
      pdf: _pdfs,
      oldPdf,
      images: _images,
      oldImages,
      lesson,
      description,
      quiz: {
        quizName,
        duration: quizDuration,
        passingCriteria,
        questions:
          quizQuestions?.length > 0 ? quizQuestions.map((q) => q._id) : "",
      },
      hours,
      minutes,
      descriptionFile,
    };

    if (selectedImageFile) {
      finalParams.video = selectedImageFile;
      finalParams.isVideo = true;
    } else {
      finalParams.isVideo = false;
    }

    handleSubmit(finalParams);
  };

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onload = (event) => {
      setSelectedImage(event.target.result);

      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        // setVideoDuration(millisToMinutesAndSeconds(media.duration * 1000));
      };
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen} overflow type={"big"}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Topic
        </span>

        {/* Topic Name */}
        <div>
          <label>Topic Name</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Topic Name Here"
            value={topicName}
            onChange={(e) => {
              setTopicName(e.target.value);
            }}
          />
        </div>

        {/* Quiz Questions */}
        <div className="mt-3">
          <label>Select Questions (For Quiz)</label>
          <Select
            defaultValue={quizQuestions}
            isMulti
            options={questionArray}
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={(option) => option.question}
            getOptionValue={(option) => option._id}
            onChange={(e) => {
              setQuizQuestions(e);
            }}
          />
        </div>

        {/* Quiz Name */}
        <div className="mt-3">
          <label>Quiz Name</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Quiz Name Here"
            value={quizName}
            onChange={(e) => {
              setQuizName(e.target.value);
            }}
          />
        </div>

        {/* Quiz Duration */}
        <div className="mt-3">
          <label>Quiz Duration (in minutes)</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            placeholder="Quiz Duration Here (in minutes)"
            value={quizDuration}
            onChange={(e) => {
              setQuizDuration(e.target.value);
            }}
          />
        </div>
        {/* passingCriteria */}
        <div className="mt-3">
          <label>Passing Criteria (in %)</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Passing criteria (in %)"
            value={passingCriteria}
            onChange={(e) => {
              setPassingCriteria(e.target.value);
            }}
          />
        </div>

        {/* Duration */}
        <div className="mt-3">
          <label>Duration (Hours)</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder={`Duration (Hours) Here`}
            value={hours}
            onChange={(e) => {
              setHours(e.target.value.replace(numberRegEx, ""));
            }}
            rows={3}
          />
        </div>
        <div className="mt-3">
          <label>Duration (Minutes)</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder={`Duration (Minutes) Here`}
            value={minutes}
            onChange={(e) => {
              setMinutes(e.target.value?.replace(numberRegEx, ""));
            }}
            rows={3}
          />
        </div>

        {/* Description */}
        <div className="mt-3">
          <label>Description</label>
          {isLoadingQuill ? (
            <p className="text-center font-lg">Loading Content...</p>
          ) : (
            <ReactQuill
              modules={modules}
              type="text"
              placeholder="Description Here"
              value={description}
              onChange={(e) => {
                setDescription(e);
              }}
            />
          )}
        </div>

        {/* PDFS */}
        <div className="mt-3">
          <label>Pdfs</label>
          <div className="sliderContainer">
            {pdfs.map((item, index) => (
              <AddImageComponent
                index={index}
                item={item}
                onSubmit={handlePdfsUpdate}
                initialState={pdfs}
                fileTypeArray={["application/pdf"]}
                fileType="pdf"
                setOldPdf={setOldPdf}
                oldPdf={oldPdf}
                topicId={data?._id}
              />
            ))}
            <AddImageComponent
              onSubmit={handlePdfsUpdate}
              initialState={pdfs}
              isAddEmpty={true}
            />
          </div>
        </div>

        {/* Images */}
        <div className="mt-3">
          <label>Images</label>
          <div className="sliderContainer">
            {images.map((item, index) => (
              <AddImageComponent
                index={index}
                item={item}
                onSubmit={handleImagesUpdate}
                initialState={images}
                setOldPdf={setOldImages}
                oldPdf={oldImages}
                topicId={data?._id}
              />
            ))}
            <AddImageComponent
              onSubmit={handleImagesUpdate}
              initialState={images}
              isAddEmpty={true}
            />
          </div>
        </div>

        {/* Video */}
        <div class="form-group" className="seo__imagePicker">
          <input
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            name="uploadfile"
            id="img"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event);
            }}
          />
          <label for="exampleInputEmail1">Select Video</label>
          <label
            for="img"
            className="btn btn-primary btn-lg btn-block"
            style={{
              backgroundColor: green[500],
            }}
            onChange={(e) => {
              // console.log(e.target.files[0]);
            }}
          >
            {selectedImage ? "Change selection" : "Pick a Video"}
          </label>
        </div>

        {/* Shoe Image */}
        {selectedImage && (
          <div>
            <CardMedia
              component="video"
              height="194"
              controls
              autoplay
              src={
                selectedImageFile == null ? `${selectedImage}` : selectedImage
              }
            />
          </div>
        )}

        {isLoading && (
          <div className="circular-loader">
            {videoProgress > 1 && <CircularLoader val={videoProgress} />}
            Please Wait...
          </div>
        )}
        {/* btn */}
        {!isLoading && videoProgress == 0 && (
          <div className="container-login100-form-btn mt-4">
            <div className="wrap-login100-form-btn">
              <div className="login100-form-bgbtn"></div>
              <button
                disabled={isLoading}
                className="login100-form-btn"
                onClick={btnClickHandler}
              >
                {isLoading ? "Please Wait..." : "Submit"}
              </button>
            </div>
          </div>
        )}
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditTopicModal;
