export const countyData = [
  {
    county: "Alameda",
    courtCode: [
      {
        label: "01430",
        label: "01430",
        courtName: "Fremont Traffic Court",
        courtCity: "Fremont",
      },
      {
        label: "01440",
        label: "01440",
        courtName: "Oakland Traffic Court",
        courtCity: "Oakland",
      },
    ],
  },
  {
    county: "Alpine",
    courtCode: [
      {
        label: "02100",
        label: "02100",
        courtName: "Alpine County Superior Court (Traffic and Criminal)",
        courtCity: "Markleeville",
      },
      {
        label: "02200",
        label: "02200",
        courtName: "Alpine Superior Court (Juvenile Court)",
        courtCity: "Markleeville",
      },
    ],
  },
  {
    county: "Amador",
    courtCode: [
      {
        label: "03610",
        label: "03610",
        courtName: "Amador County Superior Court",
        courtCity: "Jackson",
      },
    ],
  },
  {
    county: "Butte",
    courtCode: [
      {
        label: "04100",
        label: "04100",
        courtName: "Butte County Superior Court",
        courtCity: "Oroville",
      },
    ],
  },
  {
    county: "Calaveras",
    courtCode: [
      {
        label: "05100",
        label: "05100",
        courtName: "Calaveras County Superior Court",
        courtCity: "San Andreas",
      },
    ],
  },
  {
    county: "Colusa",
    courtCode: [
      {
        label: "06620",
        label: "06620",
        courtName: "Colusa County Superior",
        courtCity: "Court Colusa",
      },
    ],
  },
  {
    county: "Contra Costa",
    courtCode: [
      {
        label: "07410",
        label: "07410",
        courtName: "Concord Superior Court",
        courtCity: "Concord",
      },
      {
        label: "07460",
        label: "07460",
        courtName: "Richmond Superior Court",
        courtCity: "Richmond",
      },
      {
        label: "07465",
        label: "07465",
        courtName: "Pittsburg Superior Court",
        courtCity: "Pittsburg",
      },
      {
        label: "07480",
        label: "07480",
        courtName: "Walnut Creek Superior Court",
        courtCity: "Walnut Creek",
      },
    ],
  },
  {
    county: "Del Norte",
    courtCode: [
      {
        label: "08100",
        label: "08100",
        courtName: "Del Norte Superior Court",
        courtCity: "Crescent City",
      },
    ],
  },
  {
    county: "El Dorado",
    courtCode: [
      {
        label: "09660",
        label: "09660",
        courtName: "El Dorado Superior Court",
        courtCity: "South Lake Tahoe",
      },
      {
        label: "09670",
        label: "09670",
        courtName: "El Dorado Superior Court",
        courtCity: "Placerville",
      },
    ],
  },
  {
    county: "Fresno",
    courtCode: [
      {
        label: "10440",
        label: "10440",
        courtName: "Fresno Superior Court",
        courtCity: "Fresno",
      },
    ],
  },
  {
    county: "Glenn",
    courtCode: [
      {
        label: "11100",
        label: "11100",
        courtName: "Willows Branch",
        courtCity: "Willows",
      },
    ],
  },
  {
    county: "Humboldt",
    courtCode: [
      {
        label: "12100",
        label: "12100",
        courtName: "Humboldt County Superior",
        courtCity: "Eureka",
      },
    ],
  },
  {
    county: "Imperial",
    courtCode: [
      {
        label: "13100",
        label: "13100",
        courtName: "Imperial County Superior Court",
        courtCity: "El Centro",
      },
      {
        label: "13420",
        label: "13420",
        courtName: "Brawley Branch",
        courtCity: "Brawley",
      },
      {
        label: "13430",
        label: "13430",
        courtName: "Calexico Branch",
        courtCity: "Calexico",
      },
      {
        label: "13440",
        label: "13440",
        courtName: "Imperial County Superior Court",
        courtCity: "El Centro",
      },
      {
        label: "13450",
        label: "13450",
        courtName: "Winterhaven Branch",
        courtCity: "Winterhaven",
      },
    ],
  },
  {
    county: "Inyo",
    courtCode: [
      {
        label: "14660",
        label: "14660",
        courtName: "Inyo County Superior",
        courtCity: "Bishop",
      },
    ],
  },
  {
    county: "Inyo",
    courtCode: [
      {
        label: "14660",
        label: "14660",
        courtName: "Inyo County Superior",
        courtCity: "Bishop",
      },
    ],
  },
  {
    county: "Kern",
    courtCode: [
      {
        value: "15410",
        label: "15410",
        courtName: "Arvin Lamont ",
        courtCity: "Lamont",
      },
      {
        value: "15420",
        label: "15420",
        courtName: "Metro Division ",
        courtCity: "Bakersfield",
      },
      {
        value: "15430",
        label: "15430",
        courtName: "Delano McFarland",
        courtCity: "Delano",
      },
      {
        value: "15440",
        label: "15440",
        courtName: "Taft Maricopa ",
        courtCity: "Taft",
      },
      {
        value: "15445",
        label: "15445",
        courtName: "Shafter Wasco",
        courtCity: "Shafter",
      },
      {
        value: "15460",
        label: "15460",
        courtName: "Mojave",
        courtCity: "Mojave",
      },
      {
        value: "15470",
        label: "15470",
        courtName: "Ridgecrest",
        courtCity: "Ridgecrest",
      },
    ],
  },

  {
    county: "Kings",
    courtCode: [
      {
        value: "16420",
        label: "16420",
        courtName: "Hanford Division",
        courtCity: "Hanford",
      },
      {
        value: "16610",
        label: "16610",
        courtName: "Avenal Division",
        courtCity: "Avenal",
      },
      {
        value: "16620",
        label: "16620",
        courtName: "Corcoran Division",
        courtCity: "Corcoran",
      },
      {
        value: "16660",
        label: "16660",
        courtName: "Lemoore Division",
        courtCity: "Lemoore",
      },
    ],
  },
  {
    county: "Lake",
    courtCode: [
      {
        value: "17100",
        label: "17100",
        courtName: "Lakeport Traffic Division",
        courtCity: "Lakeport",
      },
      {
        value: "17610",
        label: "17610",
        courtName: "Clearlake Traffic Division",
        courtCity: "Clearlake",
      },
    ],
  },
  {
    county: "Lassen",
    courtCode: [
      {
        value: "18200",
        label: "18200",
        courtName: "Lassen Probation Dept- Juvenile Traffic",
        courtCity: "Lassen",
      },
      {
        value: "18675",
        label: "18675",
        courtName: "Lassen Superior Court Hall of Justice",
        courtCity: "Susanville",
      },
    ],
  },
  {
    county: "Los Angeles",
    courtCode: [
      {
        value: "19463",
        label: "19463",
        courtName: "Central District Metropolitan",
        courtCity: "Los Angeles",
      },
    ],
  },
  {
    county: "Madera",
    courtCode: [
      {
        value: "20100",
        label: "20100",
        courtName: "Madera County Superior",
        courtCity: "Court Madera",
      },
      {
        value: "20230",
        label: "20230",
        courtName: "Madera Juvenile Court ",
        courtCity: "Madera",
      },
      {
        value: "20620",
        label: "20620",
        courtName: "Traffic Division",
        courtCity: "Madera",
      },
      {
        value: "20640",
        label: "20640",
        courtName: "Borden Division",
        courtCity: "Madera",
      },
      {
        value: "20660",
        label: "20660",
        courtName: "Criminal Division",
        courtCity: "Madera",
      },
      {
        value: "20680",
        label: "20680",
        courtName: "Sierra Division",
        courtCity: "Bass Lake",
      },
    ],
  },
  {
    county: "Marin",
    courtCode: [
      {
        value: "21420",
        label: "21420",
        courtName: "Marin County Superior Court",
        courtCity: "San Rafael",
      },
    ],
  },
  {
    county: "Mariposa",
    courtCode: [
      {
        value: "22200",
        label: "22200",
        courtName: "Mariposa Juvenile Court",
        courtCity: "Mariposa",
      },
      {
        value: "22650",
        label: "22650",
        courtName: "Mariposa Judicial District",
        courtCity: "Mariposa",
      },
    ],
  },
  {
    county: "Mendocino",
    courtCode: [
      {
        value: "23210",
        label: "23210",
        courtName: "Mendocino Juvenile Probation",
        courtCity: "Ukiah",
      },
      {
        value: "22650",
        label: "22650",
        courtName: "Ukiah Branch",
        courtCity: "Ukiah",
      },
    ],
  },
  {
    county: "Merced",
    courtCode: [
      {
        value: "24400",
        label: "24400",
        courtName: "Merced County Superior Court",
        courtCity: "Merced",
      },
    ],
  },
  {
    county: "Modoc",
    courtCode: [
      {
        value: "25100",
        label: "25100",
        courtName: "Modoc County Superior Court",
        courtCity: "Alturas",
      },
    ],
  },
  {
    county: "Mono",
    courtCode: [
      {
        value: "26660",
        label: "26660",
        courtName: "Mammoth Lakes/Bridgeport Branches",
        courtCity: "Mammoth Lakes",
      },
    ],
  },
  {
    county: "Monterey",
    courtCode: [
      {
        value: "27450",
        label: "27450",
        courtName: "Monterey County Superior Court",
        courtCity: "Marina",
      },
    ],
  },
  {
    county: "Napa",
    courtCode: [
      {
        value: "28100",
        label: "28100",
        courtName: "Criminal Courthouse",
        courtCity: "Napa",
      },
    ],
  },
  {
    county: "Nevada",
    courtCode: [
      {
        value: "29200",
        label: "29200",
        courtName: "Nevada City Juvenile Court",
        courtCity: "Nevada City",
      },
      {
        value: "29210",
        label: "29210",
        courtName: "Truckee Juvenile Court",
        courtCity: "Truckee",
      },
      {
        value: "29460",
        label: "29460",
        courtName: "Nevada City Branch",
        courtCity: "Nevada City",
      },
      {
        value: "29480",
        label: "29480",
        courtName: "Truckee Branch",
        courtCity: "Truckee",
      },
    ],
  },
  {
    county: "Orange",
    courtCode: [
      {
        value: "30470",
        label: "30470",
        courtName: "Orange County Superior Court",
        courtCity: "Santa Ana",
      },
    ],
  },
  {
    county: "Placer",
    courtCode: [
      {
        value: "31455",
        label: "31455",
        courtName: "Bill Santucci Justice Center",
        courtCity: "Roseville",
      },
    ],
  },
  {
    county: "Plumas",
    courtCode: [
      {
        value: "32100",
        label: "32100",
        courtName: "Plumas Superior Court",
        courtCity: "Quincy",
      },
      {
        value: "32651",
        label: "32651",
        courtName: "Plumas Juvenile",
        courtCity: "Portola",
      },
    ],
  },
  {
    county: "Riverside",
    courtCode: [
      {
        value: "33450",
        label: "33450",
        courtName: "Larson Justice Center",
        courtCity: "Indio",
      },
      {
        value: "33460",
        label: "33460",
        courtName: "Moreno Valley",
        courtCity: "Moreno Valley",
      },
      {
        value: "33100",
        label: "33100",
        courtName: "Robert Presley Hall of Justice",
        courtCity: "Riverside",
      },
      {
        value: "33420",
        label: "33420",
        courtName: "Corona Court",
        courtCity: "Riverside",
      },
      {
        value: "33440",
        label: "33440",
        courtName: "Hemet Courthouse",
        courtCity: "Riverside",
      },
      {
        value: "33442",
        label: "33442",
        courtName: "Banning Courthouse",
        courtCity: "Riverside",
      },
      {
        value: "33490",
        label: "33490",
        courtName: "Southwest Justice Center",
        courtCity: "Riverside",
      },
      {
        value: "33491",
        label: "33491",
        courtName: "Temecula Courthouse",
        courtCity: "Riverside",
      },
      {
        value: "33480",
        label: "33480",
        courtName: "Blythe Courthouse",
        courtCity: "Riverside",
      },
    ],
  },
  {
    county: "Sacramento",
    courtCode: [
      {
        value: "34470",
        label: "34470",
        courtName: "Superior Court of California",
        courtCity: "Sacramento",
      },
    ],
  },
  {
    county: "San Benito",
    courtCode: [
      {
        value: "35100",
        label: "35100",
        courtName: "San Benito County",
        courtCity: "Hollister",
      },
    ],
  },

  // !
  {
    county: "San Bernardino",
    courtCode: [
      {
        label: "36100",
        label: "36100",
        courtName: "San Bernardino District",
        courtCity: "San Bernardino",
      },
      {
        label: "36100",
        label: "36100",
        courtName: "Criminal and Traffic",
        courtCity: "San Bernardino",
      },
      {
        label: "36110",
        label: "36110",
        courtName: "San Bernardino Superior Court",
        courtCity: "Rancho Cucamonga",
      },
      {
        label: "36120",
        label: "36120",
        courtName: "San Bernardino Superior Court",
        courtCity: "Victorville",
      },
      {
        label: "36130",
        label: "36130",
        courtName: "San Bernardino Superior Court",
        courtCity: "Barstow",
      },
      {
        label: "36140",
        label: "36140",
        courtName: "San Bernardino Superior Court",
        courtCity: "Joshua Tree",
      },
      {
        label: "36201",
        label: "36201",
        courtName: "San Bernardino Juvenile (On East Gilbert St., Bldg. #35)",
        courtCity: "San Bernardino",
      },
      {
        label: "36211",
        label: "36211",
        courtName: "San Bernardino Juvenile",
        courtCity: "San Bernardino",
      },
      {
        label: "36221",
        label: "36221",
        courtName: "Victorville Juvenile",
        courtCity: "Victorville",
      },
      {
        label: "36480",
        label: "36480",
        courtName: "San Bernardino Superior Court",
        courtCity: "Fontana",
      },
      {
        label: "36495",
        label: "36495",
        courtName: "San Bernardino Superior Court",
        courtCity: "Rancho Cucamonga",
      },
      {
        label: "36677",
        label: "36677",
        courtName: "San Bernardino Superior Court",
        courtCity: "Joshua Tree",
      },
    ],
  },
  {
    county: "San Diego",
    courtCode: [
      {
        label: "37200",
        label: "37200",
        courtName: "San Diego Juvenile Court",
        courtCity: "San Diego",
      },
      {
        label: "37440",
        label: "37440",
        courtName: "East County",
        courtCity: "El Cajon",
      },
      {
        label: "37465",
        label: "37465",
        courtName: "North County",
        courtCity: "Vista",
      },
      {
        label: "37480",
        label: "37480",
        courtName: "Kearny Mesa Traffic Court",
        courtCity: "San Diego",
      },
      {
        label: "37485",
        label: "37485",
        courtName: "South County",
        courtCity: "Chula Vista",
      },
    ],
  },
  {
    county: "San Francisco",
    courtCode: [
      {
        label: "38200",
        label: "38200",
        courtName: "San Francisco Juvenile Court",
        courtCity: "San Francisco",
      },
      {
        label: "38460",
        label: "38460",
        courtName: "San Francisco Juvenile Court",
        courtCity: "San Francisco",
      },
    ],
  },
  {
    county: "San Joaquin",
    courtCode: [
      {
        label: "39100",
        label: "39100",
        courtName: "San Joaquin Court",
        courtCity: "French Camp",
      },
      {
        label: "39460",
        label: "39460",
        courtName: "San Joaquin Superior Court",
        courtCity: "Stockton",
      },
    ],
  },
  {
    county: "San Luis Obispo",
    courtCode: [
      {
        label: "39100",
        label: "39100",
        courtName: "San Joaquin Court",
        courtCity: "San Luis Obispo",
      },
      {
        label: "39460",
        label: "39460",
        courtName: "San Joaquin Superior Court",
        courtCity: "San Luis Obispo",
      },
    ],
  },
  {
    county: "San Mateo",
    courtCode: [
      {
        label: "41210",
        label: "41210",
        courtName: "Juvenile Traffic Court",
        courtCity: "San Mateo",
      },
      {
        label: "41470",
        label: "41470",
        courtName: "Southern Traffic Division",
        courtCity: "Redwood City",
      },
    ],
  },
  {
    county: "Santa Barbara",
    courtCode: [
      {
        label: "42460",
        label: "42460",
        courtName: "Figueroa Division",
        courtCity: "Santa Barbara",
      },
      {
        label: "42465",
        label: "42465",
        courtName: "Santa Maria Miller Division",
        courtCity: "Santa Maria",
      },
      {
        label: "42470",
        label: "42470",
        courtName: "Lompoc Division",
        courtCity: "Lompoc",
      },
      {
        label: "42675",
        label: "42675",
        courtName: "Solvang Division",
        courtCity: "Solvang",
      },
    ],
  },
  {
    county: "Santa Clara",
    courtCode: [
      {
        label: "43460",
        label: "43460",
        courtName: "Palo Alto Courthouse",
        courtCity: "Palo Alto",
      },
      {
        label: "43471",
        label: "43471",
        courtName: "Santa Clara Courthouse",
        courtCity: "Santa Clara",
      },
      {
        label: "43635",
        label: "43635",
        courtName: "South County Morgan Hill Courthouse",
        courtCity: "Morgan Hill",
      },
    ],
  },
  {
    county: "Shasta",
    courtCode: [
      {
        label: "45670",
        label: "45670",
        courtName: "Shasta County Superior Court Traffic",
        courtCity: "Redding",
      },
    ],
  },
  {
    county: "Sierra",
    courtCode: [
      {
        label: "46100",
        label: "46100",
        courtName: "Sierra County Superior Court",
        courtCity: "Downieville",
      },
    ],
  },
  {
    county: "Siskiyou",
    courtCode: [
      {
        label: "47630",
        label: "47630",
        courtName: "Dorris Tulelake Branch",
        courtCity: "Dorris",
      },
      {
        label: "47650",
        label: "47650",
        courtName: "Happy Camp Branch",
        courtCity: "Happy Camp",
      },
      {
        label: "47670",
        label: "47670",
        courtName: "Weed Branch",
        courtCity: "Weed",
      },
      {
        label: "47690",
        label: "47690",
        courtName: "Yreka Courthouse",
        courtCity: "Yreka",
      },
    ],
  },
  {
    county: "Solano",
    courtCode: [
      {
        label: "48200",
        label: "48200",
        courtName: "Juvenile Traffic Court",
        courtCity: "Fairfield",
      },
      {
        label: "48430",
        label: "48430",
        courtName: "Hall of Justice",
        courtCity: "Fairfield",
      },
      {
        label: "48480",
        label: "48480",
        courtName: "Solano Justice Center",
        courtCity: "Vallejo",
      },
    ],
  },
  {
    county: "Sonoma",
    courtCode: [
      {
        label: "49100",
        label: "49100",
        courtName: "Sonoma County Superior Court",
        courtCity: "Santa Rosa",
      },
      {
        label: "49200",
        label: "49200",
        courtName: "Sonoma County Superior Court Juvenile",
        courtCity: "Santa Rosa",
      },
      {
        label: "49460",
        label: "49460",
        courtName: "Sonoma County Superior Court-Traffic",
        courtCity: "Santa Rosa",
      },
    ],
  },
  {
    county: "Stanislaus",
    courtCode: [
      {
        label: "50200",
        label: "50200",
        courtName: "Juvenile Division (on Blue Gum Ave)",
        courtCity: "Modesto",
      },
      {
        label: "50450",
        label: "50450",
        courtName: "Traffic Division (On Floyd Ave)",
        courtCity: "Modesto",
      },
    ],
  },
  {
    county: "Sutter",
    courtCode: [
      {
        label: "51460",
        label: "51460",
        courtName: "Sutter County Superior Court",
        courtCity: "Yuba City",
      },
    ],
  },
  {
    county: "Tehama",
    courtCode: [
      {
        label: "52660",
        label: "52660",
        courtName: "Red Bluff Courthouse",
        courtCity: "Red Bluff",
      },
    ],
  },
  {
    county: "Trinity",
    courtCode: [
      {
        label: "53100",
        label: "53100",
        courtName: "Trinity County Superior Court",
        courtCity: "Weaverville",
      },
    ],
  },
  {
    county: "Tulare",
    courtCode: [
      {
        label: "54100",
        label: "54100",
        courtName: "Tulare County Superior Court",
        courtCity: "Visalia",
      },
      {
        label: "54465",
        label: "54465",
        courtName: "Porterville Division",
        courtCity: "Porterville",
      },
      {
        label: "54485",
        label: "54485",
        courtName: "Tulare-Pixley Division",
        courtCity: "Tulare",
      },
      {
        label: "54490",
        label: "54490",
        courtName: "County Civic Center, Room 124",
        courtCity: "Visalia",
      },
    ],
  },
  {
    county: "Tuolumne",
    courtCode: [
      {
        label: "55100",
        label: "55100",
        courtName: "Tuolumne County Superior Court",
        courtCity: "Sonora",
      },
    ],
  },
  {
    county: "Ventura",
    courtCode: [
      {
        label: "56100",
        label: "56100",
        courtName: "Ventura Division",
        courtCity: "Ventura",
      },
      {
        label: "56200",
        label: "56200",
        courtName: "Juvenile Courthouse",
        courtCity: "Ventura",
      },
    ],
  },
  {
    county: "Yolo",
    courtCode: [
      {
        label: "57420",
        label: "57420",
        courtName: "Yolo County Superior Court",
        courtCity: "Woodland",
      },
    ],
  },
  {
    county: "Yuba",
    courtCode: [
      {
        label: "58100",
        label: "58100",
        courtName: "Yuba County Superior Court",
        courtCity: "Marysville",
      },
    ],
  },
];

export const leaCodes = [
  {
    lawEnforcementAgencyDescription: "CARSON POLICE DEPARTMENT",
    leaCode: 12,
  },
  {
    lawEnforcementAgencyDescription: "CALIFORNIA STATE UNIVERSITY DOMINGUEZ",
    leaCode: 43,
  },
  {
    lawEnforcementAgencyDescription: "ANIMAL REGULATION",
    leaCode: 90,
  },
  {
    lawEnforcementAgencyDescription: "LASD-FIRESTONE STATION",
    leaCode: 101,
  },
  {
    lawEnforcementAgencyDescription: "COUNTY PKS &amp; REC",
    leaCode: 150,
  },
  {
    lawEnforcementAgencyDescription: "LA COUNTY SAF POL",
    leaCode: 186,
  },
  {
    lawEnforcementAgencyDescription: "CARPENTERIA POLICE DEPARTMENT",
    leaCode: 188,
  },
  {
    lawEnforcementAgencyDescription: "S F TRNST POL DET",
    leaCode: 192,
  },
  {
    lawEnforcementAgencyDescription: "SN BERNARDINO CTY",
    leaCode: 202,
  },
  {
    lawEnforcementAgencyDescription: "CITY OF LOMITA",
    leaCode: 204,
  },
  {
    lawEnforcementAgencyDescription: "CITY OF NORWALK",
    leaCode: 209,
  },
  {
    lawEnforcementAgencyDescription: "METRO RAIL",
    leaCode: 216,
  },
  {
    lawEnforcementAgencyDescription: "LASD-SAN DIMAS STATION PATROL",
    leaCode: 801,
  },
  {
    lawEnforcementAgencyDescription: "GARDEN GROVE",
    leaCode: 1039,
  },
  {
    lawEnforcementAgencyDescription: "LASD-CRESCENTA VALLEY PATROL",
    leaCode: 1201,
  },
  {
    lawEnforcementAgencyDescription: "LASD-AVALON STAT",
    leaCode: 1801,
  },
  {
    lawEnforcementAgencyDescription: " LOS ANGELES COUNTY SHERIFF",
    leaCode: 1900,
  },
  {
    lawEnforcementAgencyDescription: "ALHAMBRA POLICE DEPARTMENT",
    leaCode: 1901,
  },
  {
    lawEnforcementAgencyDescription: "ARCADIA POLICE DEPARTMENT",
    leaCode: 1902,
  },
  {
    lawEnforcementAgencyDescription: "AZUSA POLICE DEPARTMENT",
    leaCode: 1905,
  },
  {
    lawEnforcementAgencyDescription: "BALDWIN PARK POLICE DEPARTMENT",
    leaCode: 1906,
  },
  {
    lawEnforcementAgencyDescription: "BELL POLICE DEPARTMENT",
    leaCode: 1907,
  },
  {
    lawEnforcementAgencyDescription: "BALDWIN PARK UNIFIED SCHOOL",
    leaCode: 1908,
  },
  {
    lawEnforcementAgencyDescription: "BELL GARDENS POLICE DEPARTMENT",
    leaCode: 1909,
  },
  {
    lawEnforcementAgencyDescription: "BEVERLY HILLS POLICE DEPARTMENT",
    leaCode: 1910,
  },
  {
    lawEnforcementAgencyDescription: "BRADBURY POLICE",
    leaCode: 1911,
  },
  {
    lawEnforcementAgencyDescription: "BURBANK POLICE DEPARTMENT",
    leaCode: 1912,
  },
  {
    lawEnforcementAgencyDescription: "CLAREMONT POLICE DEPARTMENT",
    leaCode: 1913,
  },
  {
    lawEnforcementAgencyDescription: "COMMERCE POLICE DEPARTMENT",
    leaCode: 1914,
  },
  {
    lawEnforcementAgencyDescription: "COMPTON POLICE DEPARTMENT",
    leaCode: 1915,
  },
  {
    lawEnforcementAgencyDescription: "COVINA POLICE DEPARTMENT",
    leaCode: 1916,
  },
  {
    lawEnforcementAgencyDescription: "CUDAHY POLICE DEPATMENT",
    leaCode: 1917,
  },
  {
    lawEnforcementAgencyDescription: "CULVER CITY POLICE DEPARTMENT",
    leaCode: 1918,
  },
  {
    lawEnforcementAgencyDescription: "DOWNEY POLICE DEPARTMENT",
    leaCode: 1920,
  },
  {
    lawEnforcementAgencyDescription: "DUARTE POLICE DEPARTMENT",
    leaCode: 1921,
  },
  {
    lawEnforcementAgencyDescription: "EL MONTE POLICE DEPARTMENT",
    leaCode: 1922,
  },
  {
    lawEnforcementAgencyDescription: "EL SEGUNDO POLICE DEPARTMENT",
    leaCode: 1923,
  },
  {
    lawEnforcementAgencyDescription: "GARDENA POLICE DEPARTMENT",
    leaCode: 1924,
  },
  {
    lawEnforcementAgencyDescription: "GLENDALE POLICE DEPARTMENT",
    leaCode: 1925,
  },
  {
    lawEnforcementAgencyDescription: "GLENDORA POLICE DEPARTMENT",
    leaCode: 1926,
  },
  {
    lawEnforcementAgencyDescription: "HAWAIIAN GARDENS POLICE DEPARTMENT",
    leaCode: 1927,
  },
  {
    lawEnforcementAgencyDescription: "HAWTHORNE POLICE DEPARTMENT",
    leaCode: 1928,
  },
  {
    lawEnforcementAgencyDescription: "HERMOSA BEACH POLICE DEPARTMENT",
    leaCode: 1929,
  },
  {
    lawEnforcementAgencyDescription: "HUNTINGTON PARK POLICE DEPARTMENT",
    leaCode: 1931,
  },
  {
    lawEnforcementAgencyDescription: "INGLEWOOD POLICE DEPARTMENT",
    leaCode: 1933,
  },
  {
    lawEnforcementAgencyDescription: "IRWINDALE POLICE DEPARTMENT",
    leaCode: 1934,
  },
  {
    lawEnforcementAgencyDescription: "LAVERNE POLICE DEPARTMENT",
    leaCode: 1938,
  },
  {
    lawEnforcementAgencyDescription: "LAWNDALE POLICE DEPARTMENT",
    leaCode: 1939,
  },
  {
    lawEnforcementAgencyDescription: "LOMITA POLICE DEPARTMENT",
    leaCode: 1940,
  },
  {
    lawEnforcementAgencyDescription: "LONG BEACH POLICE DEPARTMENT",
    leaCode: 1941,
  },
  {
    lawEnforcementAgencyDescription: "LOS ANGELES POLICE DEPARTMENT",
    leaCode: 1942,
  },
  {
    lawEnforcementAgencyDescription: "LOS ANGELES INTERNATIONAL AIRPORT PD",
    leaCode: 1943,
  },
  {
    lawEnforcementAgencyDescription: "MANHATTAN BEACH POLICE DEPARTMENT",
    leaCode: 1944,
  },
  {
    lawEnforcementAgencyDescription: "MAYWOOD POLICE DEPARTMENT",
    leaCode: 1945,
  },
  {
    lawEnforcementAgencyDescription: "MONROVIA POLICE DEPARTMENT",
    leaCode: 1946,
  },
  {
    lawEnforcementAgencyDescription: "MONTEBELLO POLICE DEPARTMENT",
    leaCode: 1947,
  },
  {
    lawEnforcementAgencyDescription: "MONTEREY PARK POLICE DEPARTMENT",
    leaCode: 1948,
  },
  {
    lawEnforcementAgencyDescription: "PALOS VERDES ESTATES POLICE DEPARTMENT",
    leaCode: 1951,
  },
  {
    lawEnforcementAgencyDescription: "PASADENA POLICE DEPARTMENT",
    leaCode: 1953,
  },
  {
    lawEnforcementAgencyDescription: "POMONA POLICE DEPARTMENT",
    leaCode: 1955,
  },
  {
    lawEnforcementAgencyDescription: "REDONDO BEACH POLICE DEPARTMENT",
    leaCode: 1956,
  },
  {
    lawEnforcementAgencyDescription: "ROLLING HILLS POLICE DEPARTMENT",
    leaCode: 1957,
  },
  {
    lawEnforcementAgencyDescription: "ROLLING HILLS ESTATES POLICE DEPARTMENT",
    leaCode: 1958,
  },
  {
    lawEnforcementAgencyDescription: "SAN FERNANDO POLICE DEPARTMENT",
    leaCode: 1961,
  },
  {
    lawEnforcementAgencyDescription: "SAN GABRIEL POLICE DEPARTMENT",
    leaCode: 1962,
  },
  {
    lawEnforcementAgencyDescription: "SAN MARINO POLICE DEPARTMENT",
    leaCode: 1963,
  },
  {
    lawEnforcementAgencyDescription: "SANTA MONICA POLICE DEPARTMENT",
    leaCode: 1965,
  },
  {
    lawEnforcementAgencyDescription: "SIERRA MADRE POLICE DEPARTMENT",
    leaCode: 1966,
  },
  {
    lawEnforcementAgencyDescription: "SIGNAL HILL POLICE DEPARTMENT",
    leaCode: 1967,
  },
  {
    lawEnforcementAgencyDescription: "SOUTH GATE POLICE DEPARTMENT",
    leaCode: 1969,
  },
  {
    lawEnforcementAgencyDescription: "SOUTH PASADENA POLICE DEPARTMENT",
    leaCode: 1970,
  },
  {
    lawEnforcementAgencyDescription: "TORRANCE P0LICE DEPARTMENT",
    leaCode: 1972,
  },
  {
    lawEnforcementAgencyDescription: "VERNON POLICE DEPARTMENT",
    leaCode: 1973,
  },
  {
    lawEnforcementAgencyDescription: "WEST COVINA POLICE DEPARTMENT",
    leaCode: 1975,
  },
  {
    lawEnforcementAgencyDescription: "WHITTIER POLICE DEPARTMENT",
    leaCode: 1976,
  },
  {
    lawEnforcementAgencyDescription: "CALIFORNIA STATE POLYTECHNIC COLLEGE",
    leaCode: 1978,
  },
  {
    lawEnforcementAgencyDescription: "DEPARTMENT OF MOTOR VEHICLES",
    leaCode: 1979,
  },
  {
    lawEnforcementAgencyDescription: "METROPOLITAN TRANSIT AUTHORITY",
    leaCode: 1980,
  },
  {
    lawEnforcementAgencyDescription: "BURBANK/GLENDALE/PASADENA AIRPORT",
    leaCode: 1981,
  },
  {
    lawEnforcementAgencyDescription: "UNIVERSITY OF CALIFORNIA LOS ANGELES",
    leaCode: 1997,
  },
  {
    lawEnforcementAgencyDescription: "CALIFORNIA STATE POLICE",
    leaCode: 1998,
  },
  {
    lawEnforcementAgencyDescription: "CALIFORNIA HIGHWAY PATROL",
    leaCode: 1999,
  },
  {
    lawEnforcementAgencyDescription: "LASD-CENTURY STATION PATROL",
    leaCode: 2100,
  },
  {
    lawEnforcementAgencyDescription: "LASD-CENTURY STA",
    leaCode: 2101,
  },
  {
    lawEnforcementAgencyDescription: "LASD-CENTURY STA",
    leaCode: 2102,
  },
  {
    lawEnforcementAgencyDescription: "LASD-COMPTON STATION PATROL",
    leaCode: 2801,
  },
  {
    lawEnforcementAgencyDescription: "TSB RAIL UNIT PATROL",
    leaCode: 6001,
  },
  {
    lawEnforcementAgencyDescription: "TSB METROLINK UNIT PATROL",
    leaCode: 6101,
  },
  {
    lawEnforcementAgencyDescription: "TSB BUS ENFORCEMENT PATROL",
    leaCode: 6801,
  },
  {
    lawEnforcementAgencyDescription: "AIRPORT SECURITY POLICE",
    leaCode: 7001,
  },
  {
    lawEnforcementAgencyDescription: "W. LOS ANGELES JUNIOR COLLEGE POLICE",
    leaCode: 7003,
  },
  {
    lawEnforcementAgencyDescription: "STATE UNIVERSITY POLICE DEPARTMENT",
    leaCode: 7004,
  },
  {
    lawEnforcementAgencyDescription: "E. LOS ANGELES COLLEGE POLICE",
    leaCode: 7005,
  },
  {
    lawEnforcementAgencyDescription: "L A COUNTY H/S",
    leaCode: 7006,
  },
  {
    lawEnforcementAgencyDescription: "COLLEGE OF THE CANYON CAMPUS POLICE",
    leaCode: 7007,
  },
  {
    lawEnforcementAgencyDescription: "L A UNIFIED SCHOOL DIST PD",
    leaCode: 7052,
  },
  {
    lawEnforcementAgencyDescription: "LA CITY PK RANGER",
    leaCode: 7053,
  },
  {
    lawEnforcementAgencyDescription: "LOS ANGELES COUNTY SAFETY POLICE",
    leaCode: 7054,
  },
  {
    lawEnforcementAgencyDescription: "LOS ANGELES COUNTY PARKS AND RECREATION",
    leaCode: 7055,
  },
  {
    lawEnforcementAgencyDescription: "HARBOR PATROL",
    leaCode: 7056,
  },
  {
    lawEnforcementAgencyDescription: "HOUSING AUTHORITY",
    leaCode: 7058,
  },
  {
    lawEnforcementAgencyDescription: "LOS ANGELES COMMUNITY COLLEGE",
    leaCode: 7059,
  },
  {
    lawEnforcementAgencyDescription: "PORT OF LOS ANGELES",
    leaCode: 7088,
  },
  {
    lawEnforcementAgencyDescription: "EL CAMINO COLLEGE POLICE DEPARTMENT",
    leaCode: 7099,
  },
  {
    lawEnforcementAgencyDescription: "COMPTON SCHOOL POLICE DEPT",
    leaCode: 7100,
  },
  {
    lawEnforcementAgencyDescription: "ANIMAL CONTROL",
    leaCode: 7203,
  },
  {
    lawEnforcementAgencyDescription: "CALIFORNIA DEPARTMENT OF FISH AND GAME",
    leaCode: 7204,
  },
  {
    lawEnforcementAgencyDescription: "CALIFORNIA PARKS AND RECREATION",
    leaCode: 7205,
  },
  {
    lawEnforcementAgencyDescription: "CALIFORNIA STATE UNIVERSITY LONG BEACH",
    leaCode: 7206,
  },
  {
    lawEnforcementAgencyDescription: "CALIFORNIA STATE UNIVERSITY LOS ANGELES",
    leaCode: 7207,
  },
  {
    lawEnforcementAgencyDescription: "CALIFORNIA STATE UNIVERSITY NORTHRIDGE",
    leaCode: 7208,
  },
  {
    lawEnforcementAgencyDescription: "CITRUS COLLEGE",
    leaCode: 7209,
  },
  {
    lawEnforcementAgencyDescription: "SEAL BEACH POLICE DEPARTMENT",
    leaCode: 7215,
  },
  {
    lawEnforcementAgencyDescription: "SANTA FE RAILROAD",
    leaCode: 7226,
  },
  {
    lawEnforcementAgencyDescription: "LOS ANGELES CITY COLLEGE",
    leaCode: 7230,
  },
  {
    lawEnforcementAgencyDescription: "HARBOR COLLEGE",
    leaCode: 7231,
  },
  {
    lawEnforcementAgencyDescription: "MISSION COLLEGE",
    leaCode: 7232,
  },
  {
    lawEnforcementAgencyDescription: "PIERCE COLLEGE",
    leaCode: 7233,
  },
  {
    lawEnforcementAgencyDescription: "TRADE TECH",
    leaCode: 7234,
  },
  {
    lawEnforcementAgencyDescription: "GLENDALE ANIMAL CONTROL",
    leaCode: 7235,
  },
  {
    lawEnforcementAgencyDescription: "LONG BEACH CITY COLLEGE",
    leaCode: 7239,
  },
  {
    lawEnforcementAgencyDescription: "SANTA ANA POLICE DEPARTMENT",
    leaCode: 7242,
  },
  {
    lawEnforcementAgencyDescription: "SOUTH EL MONTE, CITY OF",
    leaCode: 7246,
  },
  {
    lawEnforcementAgencyDescription: "CERRITOS COLLEGE",
    leaCode: 7249,
  },
  {
    lawEnforcementAgencyDescription: "SOUTHERN PACIFIC RAILROAD",
    leaCode: 7250,
  },
  {
    lawEnforcementAgencyDescription: "LA PUENTE, CITY OF",
    leaCode: 7251,
  },
  {
    lawEnforcementAgencyDescription: "CATALINA ISLAND",
    leaCode: 7253,
  },
  {
    lawEnforcementAgencyDescription: "ROSEMEAD, CITY OF",
    leaCode: 7256,
  },
  {
    lawEnforcementAgencyDescription: "LYNWOOD, CITY OF",
    leaCode: 7260,
  },
  {
    lawEnforcementAgencyDescription: "UNION PACIFIC RAILROAD",
    leaCode: 7261,
  },
  {
    lawEnforcementAgencyDescription: "NEWPORT POLICE DEPARTMENT",
    leaCode: 7266,
  },
  {
    lawEnforcementAgencyDescription: "GLENDALE COLLEGE",
    leaCode: 7269,
  },
  {
    lawEnforcementAgencyDescription: "MONTEBELLO FIRE DEPARTMENT",
    leaCode: 7270,
  },
  {
    lawEnforcementAgencyDescription: "BREA POLICE DEPARTMENT",
    leaCode: 7271,
  },
  {
    lawEnforcementAgencyDescription: "SANTA FE SPRINGS, CITY OF",
    leaCode: 7273,
  },
  {
    lawEnforcementAgencyDescription: "MOUNTAIN RECREATION CONSERVATION",
    leaCode: 7275,
  },
  {
    lawEnforcementAgencyDescription: "WESTLAKE VILLAGE, CITY OF",
    leaCode: 7277,
  },
  {
    lawEnforcementAgencyDescription: "EL RANCHO UNIFIED SCHOOL DISTRICT",
    leaCode: 7279,
  },
  {
    lawEnforcementAgencyDescription: "LA HABRA POLICE DEPARTMENT",
    leaCode: 7280,
  },
  {
    lawEnforcementAgencyDescription: "UPLND POLICE DPT",
    leaCode: 7284,
  },
  {
    lawEnforcementAgencyDescription: "BUENA PARK POLICE DEPARTMENT",
    leaCode: 7289,
  },
  {
    lawEnforcementAgencyDescription: "LOS ANGELES CITY DEPT. TRANSPORTATION",
    leaCode: 7292,
  },
  {
    lawEnforcementAgencyDescription: "MONTEBELLO USD PD",
    leaCode: 7294,
  },
  {
    lawEnforcementAgencyDescription: "TEMPLE CITY",
    leaCode: 7295,
  },
  {
    lawEnforcementAgencyDescription: "SAN DIEGO POLICE DEPARTMENT",
    leaCode: 7297,
  },
  {
    lawEnforcementAgencyDescription: "GARDEN GROVE POLICE DEPARTMENT",
    leaCode: 7298,
  },
  {
    lawEnforcementAgencyDescription: "LOS ANGELES UNIFIED SCHOOL DISTRICT",
    leaCode: 7300,
  },
  {
    lawEnforcementAgencyDescription: "LA PALMA POLICE DEPARTMENT",
    leaCode: 7301,
  },
  {
    lawEnforcementAgencyDescription: "PASADENA PHOTO RADAR",
    leaCode: 7305,
  },
  {
    lawEnforcementAgencyDescription: "ONTARIO POLICE DEPARTMENT",
    leaCode: 7309,
  },
  {
    lawEnforcementAgencyDescription: "IRVINE POLICE DEPARTMENT",
    leaCode: 7310,
  },
  {
    lawEnforcementAgencyDescription: "LANCASTER, CITY OF",
    leaCode: 7311,
  },
  {
    lawEnforcementAgencyDescription: "BASSETT UNIFIED SCHOOL DISTRICT",
    leaCode: 7313,
  },
  {
    lawEnforcementAgencyDescription: "CERRITOS, CITY OF",
    leaCode: 7314,
  },
  {
    lawEnforcementAgencyDescription: "TAX AND PERMIT",
    leaCode: 7315,
  },
  {
    lawEnforcementAgencyDescription: "PASADENA CITY COLLEGE",
    leaCode: 7316,
  },
  {
    lawEnforcementAgencyDescription: "COVINA UNIFIED SCHOOL DISTRICT",
    leaCode: 7317,
  },
  {
    lawEnforcementAgencyDescription: "MONTCLAIR POLICE DEPARTMENT",
    leaCode: 7319,
  },
  {
    lawEnforcementAgencyDescription: "SANTA BARBARA POLICE DEPARTMENT",
    leaCode: 7328,
  },
  {
    lawEnforcementAgencyDescription: "ROLLING HILLS, CITY OF",
    leaCode: 7331,
  },
  {
    lawEnforcementAgencyDescription: "LAWNDALE, CITY OF",
    leaCode: 7332,
  },
  {
    lawEnforcementAgencyDescription: "ORANGE, CITY OF",
    leaCode: 7334,
  },
  {
    lawEnforcementAgencyDescription: "DOWNEY UNIFIED SCHOOL DISTRICT",
    leaCode: 7341,
  },
  {
    lawEnforcementAgencyDescription: "LOS ANGELES VALLEY COMMUNITY COLLEGE",
    leaCode: 7347,
  },
  {
    lawEnforcementAgencyDescription: "COSTA MESA POLICE DEPARTMENT",
    leaCode: 7349,
  },
  {
    lawEnforcementAgencyDescription: "OXNARD, CITY OF",
    leaCode: 7352,
  },
  {
    lawEnforcementAgencyDescription: "BEVERLY HILLS, CITY OF",
    leaCode: 7355,
  },
  {
    lawEnforcementAgencyDescription: "FULLERTON POLICE DEPARTMENT",
    leaCode: 7362,
  },
  {
    lawEnforcementAgencyDescription: "WHITTIER UNIFIED SCHOOL DISTRICT",
    leaCode: 7363,
  },
  {
    lawEnforcementAgencyDescription: "CHINO POLICE DEPARTMENT",
    leaCode: 7364,
  },
  {
    lawEnforcementAgencyDescription: "SAN BERNARDINO COUNTY",
    leaCode: 7366,
  },
  {
    lawEnforcementAgencyDescription: "LOMITA, CITY OF",
    leaCode: 7367,
  },
  {
    lawEnforcementAgencyDescription: "COMPTON COLLEGE",
    leaCode: 7369,
  },
  {
    lawEnforcementAgencyDescription: "NORWALK, CITY OF",
    leaCode: 7371,
  },
  {
    lawEnforcementAgencyDescription: "SANTA MONICA COMMUNITY COLLEGE",
    leaCode: 7372,
  },
  {
    lawEnforcementAgencyDescription: "ARTESIA, CITY OF",
    leaCode: 7374,
  },
  {
    lawEnforcementAgencyDescription:
      "W. HOLLYWOOD CITY CODE ENFORCEMENT OFFICER",
    leaCode: 7375,
  },
  {
    lawEnforcementAgencyDescription: "GARDENA, CITY OF",
    leaCode: 7378,
  },
  {
    lawEnforcementAgencyDescription: "LOMPOC POLICE DEPARTMENT",
    leaCode: 7380,
  },
  {
    lawEnforcementAgencyDescription: "LONG BEACH SCHOOL DISTRICT",
    leaCode: 7382,
  },
  {
    lawEnforcementAgencyDescription: "CARSON, DEPARTMENT OF PUBLIC SAFETY",
    leaCode: 7387,
  },
  {
    lawEnforcementAgencyDescription: "SIMI VALLEY POLICE DEPARTMENT",
    leaCode: 7388,
  },
  {
    lawEnforcementAgencyDescription: "CARSON, CITY OF",
    leaCode: 7391,
  },
  {
    lawEnforcementAgencyDescription: "COMPTON UNIFIED SHCOOL DISTRICT",
    leaCode: 7392,
  },
  {
    lawEnforcementAgencyDescription: "LA MIRADA, CITY OF",
    leaCode: 7395,
  },
  {
    lawEnforcementAgencyDescription: "STOCKTON, CITY OF",
    leaCode: 7398,
  },
  {
    lawEnforcementAgencyDescription: "ANAHEIM POLICE DEPARTMENT",
    leaCode: 7399,
  },
  {
    lawEnforcementAgencyDescription: "LA HABRA, CITY OF",
    leaCode: 7400,
  },
  {
    lawEnforcementAgencyDescription: "PLACENTIA PD",
    leaCode: 7402,
  },
  {
    lawEnforcementAgencyDescription: "CORONA POLICE DPT",
    leaCode: 7404,
  },
  {
    lawEnforcementAgencyDescription: "RIALTO POLICE DPT",
    leaCode: 7406,
  },
  {
    lawEnforcementAgencyDescription: "ALHAMBRA SCHOOL DISTRICT",
    leaCode: 7408,
  },
  {
    lawEnforcementAgencyDescription: "CYPRESS POLICE DEPARTMENT",
    leaCode: 7409,
  },
  {
    lawEnforcementAgencyDescription: "COLTON PD",
    leaCode: 7412,
  },
  {
    lawEnforcementAgencyDescription: "WALNUT VLY USD",
    leaCode: 7413,
  },
  {
    lawEnforcementAgencyDescription: "RLP PHOTO ENFORCEMENT",
    leaCode: 7414,
  },
  {
    lawEnforcementAgencyDescription: "FONTANA POLICE DEPARTMENT",
    leaCode: 7415,
  },
  {
    lawEnforcementAgencyDescription: "HUNTINGTON BEACH POLICE DEPARTMENT",
    leaCode: 7416,
  },
  {
    lawEnforcementAgencyDescription: "WESTMINSTER POLICE DEPARTMENT",
    leaCode: 7418,
  },
  {
    lawEnforcementAgencyDescription: "ALHAMBRA, CITY OF",
    leaCode: 7419,
  },
  {
    lawEnforcementAgencyDescription: "U.S. RAILROAD",
    leaCode: 7422,
  },
  {
    lawEnforcementAgencyDescription: "MT VERNON HIGH SCHOOL SECURITY",
    leaCode: 7441,
  },
  {
    lawEnforcementAgencyDescription: "SAN DIEGO, CITY OF",
    leaCode: 7442,
  },
  {
    lawEnforcementAgencyDescription: "MALIBU, CITY OF",
    leaCode: 7443,
  },
  {
    lawEnforcementAgencyDescription: "E SAN FER VL (VN)",
    leaCode: 7449,
  },
  {
    lawEnforcementAgencyDescription: "E SAN F VL (NHWD)",
    leaCode: 7450,
  },
  {
    lawEnforcementAgencyDescription: "E LA GANG UNIT",
    leaCode: 7468,
  },
  {
    lawEnforcementAgencyDescription: "S CENTRAL GANG UN",
    leaCode: 7469,
  },
  {
    lawEnforcementAgencyDescription: "METRO GANG UNIT",
    leaCode: 7470,
  },
  {
    lawEnforcementAgencyDescription: "SAN GAB VAL GANG",
    leaCode: 7471,
  },
  {
    lawEnforcementAgencyDescription: "E SAN F VAL GANG",
    leaCode: 7472,
  },
  {
    lawEnforcementAgencyDescription: "ASIAN GANG UNIT",
    leaCode: 7473,
  },
  {
    lawEnforcementAgencyDescription: "ABC UN SCL DIST",
    leaCode: 7478,
  },
  {
    lawEnforcementAgencyDescription: "ANT VLLY UN SCL D",
    leaCode: 7479,
  },
  {
    lawEnforcementAgencyDescription: "ARCADIA UN SCL DIS",
    leaCode: 7480,
  },
  {
    lawEnforcementAgencyDescription: "AZUSA UN SCL DIST",
    leaCode: 7481,
  },
  {
    lawEnforcementAgencyDescription: "BELLFLOWER UN SCL DIS",
    leaCode: 7482,
  },
  {
    lawEnforcementAgencyDescription: "BEVERLY HLLS UN SCL D",
    leaCode: 7483,
  },
  {
    lawEnforcementAgencyDescription: "BONITA UN SCL DIS",
    leaCode: 7484,
  },
  {
    lawEnforcementAgencyDescription: "BURBANK UN SCL DIST",
    leaCode: 7485,
  },
  {
    lawEnforcementAgencyDescription: "CNT VLY UN HS DIS",
    leaCode: 7486,
  },
  {
    lawEnforcementAgencyDescription: "CHRT OAK UN SCL D",
    leaCode: 7487,
  },
  {
    lawEnforcementAgencyDescription: "CLRMNT UN SCL DIS",
    leaCode: 7488,
  },
  {
    lawEnforcementAgencyDescription: "CLVR CTY UN SCL D",
    leaCode: 7489,
  },
  {
    lawEnforcementAgencyDescription: "DUARTE UN SCL DIS",
    leaCode: 7490,
  },
  {
    lawEnforcementAgencyDescription: "E WHTR CTY UN S D",
    leaCode: 7491,
  },
  {
    lawEnforcementAgencyDescription: "ESIDE EL UN SCL D",
    leaCode: 7492,
  },
  {
    lawEnforcementAgencyDescription: "ELMNTE CTY SCL DI",
    leaCode: 7493,
  },
  {
    lawEnforcementAgencyDescription: "ELMNTE UN HS DIST",
    leaCode: 7494,
  },
  {
    lawEnforcementAgencyDescription: "EL SGND UN SCL DI",
    leaCode: 7495,
  },
  {
    lawEnforcementAgencyDescription: "GARVEY SCL DIST",
    leaCode: 7496,
  },
  {
    lawEnforcementAgencyDescription: "GLNDL UN SCL DIST",
    leaCode: 7497,
  },
  {
    lawEnforcementAgencyDescription: "GLNDRA UN SCH DIS",
    leaCode: 7498,
  },
  {
    lawEnforcementAgencyDescription: "GORMAN SCHOOL DIS",
    leaCode: 7499,
  },
  {
    lawEnforcementAgencyDescription: "HA-LPNTE UN SCL D",
    leaCode: 7500,
  },
  {
    lawEnforcementAgencyDescription: "HAWTHORN SCL DIST",
    leaCode: 7501,
  },
  {
    lawEnforcementAgencyDescription: "HRMS BCH CTY SCL",
    leaCode: 7502,
  },
  {
    lawEnforcementAgencyDescription: "HGHES-ELIZ LK U S",
    leaCode: 7503,
  },
  {
    lawEnforcementAgencyDescription: "INGL UN SCL DIST",
    leaCode: 7504,
  },
  {
    lawEnforcementAgencyDescription: "KEPPEL UN ELM S D",
    leaCode: 7505,
  },
  {
    lawEnforcementAgencyDescription: "LANCSTR SCL DIST",
    leaCode: 7506,
  },
  {
    lawEnforcementAgencyDescription: "LS VRG UN SCL DIS",
    leaCode: 7507,
  },
  {
    lawEnforcementAgencyDescription: "LAWDALE SCL DIST",
    leaCode: 7508,
  },
  {
    lawEnforcementAgencyDescription: "LENNOX SCHOOL DIS",
    leaCode: 7509,
  },
  {
    lawEnforcementAgencyDescription: "LTTL LKE SCL DIST",
    leaCode: 7510,
  },
  {
    lawEnforcementAgencyDescription: "LS NIETOS SCL DIS",
    leaCode: 7511,
  },
  {
    lawEnforcementAgencyDescription: "MAN BCH CTY SCH D",
    leaCode: 7512,
  },
  {
    lawEnforcementAgencyDescription: "MNRVIA UN SCL DIS",
    leaCode: 7513,
  },
  {
    lawEnforcementAgencyDescription: "NRWLK-LMRDA U S D",
    leaCode: 7514,
  },
  {
    lawEnforcementAgencyDescription: "PALMDALE SCL DIST",
    leaCode: 7515,
  },
  {
    lawEnforcementAgencyDescription: "PLS VRDS PEN UN S",
    leaCode: 7516,
  },
  {
    lawEnforcementAgencyDescription: "PRMNT UN SCL DIST",
    leaCode: 7517,
  },
  {
    lawEnforcementAgencyDescription: "PASADENA UN SCL D",
    leaCode: 7518,
  },
  {
    lawEnforcementAgencyDescription: "POMONA UN SCL DIS",
    leaCode: 7519,
  },
  {
    lawEnforcementAgencyDescription: "RNDO BCH CTY S D",
    leaCode: 7520,
  },
  {
    lawEnforcementAgencyDescription: "ROSEMEAD SCJ DIST",
    leaCode: 7521,
  },
  {
    lawEnforcementAgencyDescription: "ROWLAND UN SCL DI",
    leaCode: 7522,
  },
  {
    lawEnforcementAgencyDescription: "SN GBRL SCL DIST",
    leaCode: 7523,
  },
  {
    lawEnforcementAgencyDescription: "S. WHTTR SCL DIST",
    leaCode: 7524,
  },
  {
    lawEnforcementAgencyDescription: "SOL-AQ DLC U S D",
    leaCode: 7525,
  },
  {
    lawEnforcementAgencyDescription: "S. BAY UN SCL DIS",
    leaCode: 7526,
  },
  {
    lawEnforcementAgencyDescription: "TMPLE CTY UN S D",
    leaCode: 7527,
  },
  {
    lawEnforcementAgencyDescription: "TRRNCE UN SCL DIS",
    leaCode: 7528,
  },
  {
    lawEnforcementAgencyDescription: "VALLE LNDO SCL DI",
    leaCode: 7529,
  },
  {
    lawEnforcementAgencyDescription: "W. COVNA UN SCL D",
    leaCode: 7530,
  },
  {
    lawEnforcementAgencyDescription: "WSIDE UN ELM S D",
    leaCode: 7531,
  },
  {
    lawEnforcementAgencyDescription: "WHTTR UN H S DIST",
    leaCode: 7532,
  },
  {
    lawEnforcementAgencyDescription: "WILSONA ELM S D",
    leaCode: 7533,
  },
  {
    lawEnforcementAgencyDescription: "BELLFLOWER, CITY OF",
    leaCode: 7534,
  },
  {
    lawEnforcementAgencyDescription: "WISEBURN SCL DIST",
    leaCode: 7535,
  },
  {
    lawEnforcementAgencyDescription: "WM. S. HRT U HS D",
    leaCode: 7536,
  },
  {
    lawEnforcementAgencyDescription: "FOUNTAIN VLLY PD",
    leaCode: 7538,
  },
  {
    lawEnforcementAgencyDescription: "VENTURA COUNTY SHERIFF",
    leaCode: 7542,
  },
  {
    lawEnforcementAgencyDescription: "ORANGE CTY SHERIF",
    leaCode: 7544,
  },
  {
    lawEnforcementAgencyDescription: "RIVERSIDE SHE",
    leaCode: 7545,
  },
  {
    lawEnforcementAgencyDescription: "LOS ALAMITOS PD",
    leaCode: 7553,
  },
  {
    lawEnforcementAgencyDescription: "SACRAMENTO POLICE DEPARTMENT",
    leaCode: 7600,
  },
  {
    lawEnforcementAgencyDescription: "CARSON SCHOOL DISTRICT",
    leaCode: 7605,
  },
  {
    lawEnforcementAgencyDescription: "TUSTIN PD",
    leaCode: 7623,
  },
  {
    lawEnforcementAgencyDescription: "TULARE POLICE DEPARTMENT",
    leaCode: 7638,
  },
  {
    lawEnforcementAgencyDescription: "CITY OF SAN FERNANDO",
    leaCode: 7639,
  },
  {
    lawEnforcementAgencyDescription: "RLP BEVERLY HILLS",
    leaCode: 7640,
  },
  {
    lawEnforcementAgencyDescription: "LINDIO POLICE DEPARTMENT",
    leaCode: 7644,
  },
  {
    lawEnforcementAgencyDescription: "ORANGE CO DA TARGET GANG UNIT",
    leaCode: 7646,
  },
  {
    lawEnforcementAgencyDescription: "RLP CULVER CITY",
    leaCode: 7647,
  },
  {
    lawEnforcementAgencyDescription: "RLP WEST HLYWD BH",
    leaCode: 7648,
  },
  {
    lawEnforcementAgencyDescription: "RLP CHP LA COUNTY",
    leaCode: 7650,
  },
  {
    lawEnforcementAgencyDescription: "RLP CHP CITRUS",
    leaCode: 7652,
  },
  {
    lawEnforcementAgencyDescription: "RLP CHP RIO HONDO",
    leaCode: 7653,
  },
  {
    lawEnforcementAgencyDescription: "TURLOCK POLICE DPT",
    leaCode: 7654,
  },
  {
    lawEnforcementAgencyDescription: "RLP LA CIT",
    leaCode: 7657,
  },
  {
    lawEnforcementAgencyDescription: "LA CITY HOUSING DEPARTMENT",
    leaCode: 7658,
  },
  {
    lawEnforcementAgencyDescription: "RLP LONG BEACH",
    leaCode: 7659,
  },
  {
    lawEnforcementAgencyDescription: "CITY OF DUARTE PUBLIC SAFETY POLICE",
    leaCode: 7660,
  },
  {
    lawEnforcementAgencyDescription: "RLP PASADENA CITY",
    leaCode: 7661,
  },
  {
    lawEnforcementAgencyDescription: "RLP SOUTH GATE",
    leaCode: 7662,
  },
  {
    lawEnforcementAgencyDescription: "RLP LOS CERRITOS",
    leaCode: 7663,
  },
  {
    lawEnforcementAgencyDescription: "RLP MONTEBELLO",
    leaCode: 7664,
  },
  {
    lawEnforcementAgencyDescription: "RLP COMPTON CITY COM",
    leaCode: 7665,
  },
  {
    lawEnforcementAgencyDescription: "CITY OF CALABASAS",
    leaCode: 7666,
  },
  {
    lawEnforcementAgencyDescription: "ATP VALLEY COLLEGE POLICE DEPT",
    leaCode: 7667,
  },
  {
    lawEnforcementAgencyDescription: "HOLLISTER POLICE DEPARTMENT",
    leaCode: 7668,
  },
  {
    lawEnforcementAgencyDescription: "RLP EL MONTE CITY",
    leaCode: 7669,
  },
  {
    lawEnforcementAgencyDescription: "RLP INGLEWOOD CITY",
    leaCode: 7670,
  },
  {
    lawEnforcementAgencyDescription: "SAN JOSE POLICE DEPARTMENT",
    leaCode: 7671,
  },
  {
    lawEnforcementAgencyDescription: "RLP WHITTER CITY",
    leaCode: 7672,
  },
  {
    lawEnforcementAgencyDescription: "RLP HAWTHORNE CITY",
    leaCode: 7673,
  },
  {
    lawEnforcementAgencyDescription: "CITY OF COMPTON",
    leaCode: 7674,
  },
  {
    lawEnforcementAgencyDescription: "RLP SANTA CLARITA",
    leaCode: 7676,
  },
  {
    lawEnforcementAgencyDescription: "RLP SANTA FE SPGS",
    leaCode: 7677,
  },
  {
    lawEnforcementAgencyDescription: "RLP LYNWOOD CITY",
    leaCode: 7678,
  },
  {
    lawEnforcementAgencyDescription: "RLP MAYWOOD CITY",
    leaCode: 7679,
  },
  {
    lawEnforcementAgencyDescription: "RLP PARAMOUNT CITY",
    leaCode: 7680,
  },
  {
    lawEnforcementAgencyDescription: "RLP GARDENA CITY",
    leaCode: 7681,
  },
  {
    lawEnforcementAgencyDescription: "SAN GABRIEL VALLEY TRANSIT",
    leaCode: 7683,
  },
  {
    lawEnforcementAgencyDescription: "RLP BALDWIN PK",
    leaCode: 7684,
  },
  {
    lawEnforcementAgencyDescription: "RLP LANCASTER ATP",
    leaCode: 7685,
  },
  {
    lawEnforcementAgencyDescription: "RLP CITY WALNUT POM",
    leaCode: 7686,
  },
  {
    lawEnforcementAgencyDescription: "RLP CITY COVINA",
    leaCode: 7687,
  },
  {
    lawEnforcementAgencyDescription: "RLP CITY COMMERCE",
    leaCode: 7688,
  },
  {
    lawEnforcementAgencyDescription: "RLP CITY GLENDLE",
    leaCode: 7689,
  },
  {
    lawEnforcementAgencyDescription: "RLP BELL GARDENS",
    leaCode: 7691,
  },
  {
    lawEnforcementAgencyDescription: "EL CAMINO COLLEGE COMPTON PD",
    leaCode: 7694,
  },
  {
    lawEnforcementAgencyDescription: "PACIFIC HARBOR LINE RAILROAD ",
    leaCode: 7695,
  },
  {
    lawEnforcementAgencyDescription: "FRESNO, CITY OF",
    leaCode: 9516,
  },
  {
    lawEnforcementAgencyDescription: "VENTURA COUNTY C.H.P",
    leaCode: 9976,
  },
];
