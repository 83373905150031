import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Row } from "react-bootstrap";
import ReactQuill from "react-quill"; // ES6
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Get, Post } from "../Axios/AxiosFunctions";
import { BaseURL, apiHeader } from "../Config/apiUrl";
import CMSTriangleDataMananger from "../components/CMSTriangleDataMananger";
import ScreenBoiler from "../components/ScreenBoiler";
import TableRowsLoader from "../components/TableRowsLoader";

function CMSDetail() {
  const section1Ref = useRef();
  const section2Ref = useRef();

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: ["small", false, "large", "huge"] }, { color: [] }],
          ["image", "video", "link"],
        ],
      },
    }),
    []
  );

  const [searchParams] = useSearchParams();
  const pageName = searchParams?.get("identifier");
  const pageId = searchParams?.get("id");
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState(null);
  const [isGetting, setIsGetting] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [footer, setFooter] = useState("");

  const isQuillOnlyPage = [
    "cookie_policy",
    "privacy_policy",
    "accessibility_statement",
    "ada_compliance",
  ].includes(pageName);

  //  customer_support states
  const [headingLeft, setHeadingLeft] = useState("");
  const [headingRight, setHeadingRight] = useState("");
  const [headQuarters, setHeadQuarters] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [description, setDescription] = useState("");

  // get Item handler
  const getData = async () => {
    const url = BaseURL(`cms/page/${pageName}`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);

      if (pageName === "customer_support") {
        setHeadingLeft(response?.data?.data?.headingLeft);
        setHeadingRight(response?.data?.data?.headingRight);
        setHeadQuarters(response?.data?.data?.address);
        setEmail(response?.data?.data?.emailUsAt);
        setPhone(response?.data?.data?.contactAt);
        setServiceHours(response?.data?.data?.serviceTimings);
      }

      if (pageName === "footer") {
        setFooter(response?.data?.data?.license);
      }

      if (isQuillOnlyPage) {
        setDescription(response?.data?.data?.text || "");
      }
    }
  };

  // fire on component mount
  useEffect(() => {
    getData();
  }, []);

  //   handleSubmit
  const handleSubmit = async () => {
    let section1Data = "error";
    let section2Data = "error";
    const formData = new FormData();

    // Home
    if (pageName === "home") {
      section1Data = section1Ref.current.handleSubmit();
      if (section1Data !== "error") {
        formData.append("span", section1Data?.title);
        formData.append("heading", section1Data?.title2);
        formData.append("detail_description", section1Data?.description);
        if (section1Data?.image)
          formData.append("coverImage", section1Data?.image);
      }
    }

    section2Data = section2Ref.current.handleSubmit();
    if (section2Data !== "error") {
      //   Section 1
      formData.append("sec1Heading", section2Data?.title);
      formData.append("sec1Description", section2Data?.description);
      if (section2Data?.image)
        formData.append("sec1Image", section2Data?.image);
      if (section2Data?.title3) formData.append("title", section2Data?.title3);
      if (section2Data?.description3)
        formData.append("description", section2Data?.description3);

      formData.append("pageName", pageName);
      formData.append("_id", pageId);
      //   return console.log("section2Data", section2Data);

      const url = BaseURL(`cms/page/update`);
      setIsApiCall(true);
      const response = await Post(url, formData, apiHeader(accessToken, true));
      setIsApiCall(false);
      if (response !== undefined) {
        toast.success("Updated Successfully");
      }
    }
  };

  //   /handleSubmitFooter
  const handleSubmitFooter = async () => {
    let params =
      pageName === "footer"
        ? {
            license: footer,
            pageName: pageName,
            _id: pageId,
          }
        : {
            pageName: pageName,
            _id: pageId,
            address: headQuarters,
            emailUsAt: email,
            contactAt: phone,
            serviceTimings: serviceHours,
            headingLeft: headingLeft,
            headingRight: headingRight,
          };

    if (isQuillOnlyPage) {
      params = {
        pageName: pageName,
        _id: pageId,
        text: description,
      };
    }

    const formData = new FormData();
    if (isQuillOnlyPage) {
      for (const key in params) {
        formData.append(key, params[key]);
      }
    }

    const url = BaseURL(`cms/page/update`);
    setIsApiCall(true);
    const response = await Post(
      url,
      isQuillOnlyPage ? formData : params,
      apiHeader(accessToken)
    );
    setIsApiCall(false);
    if (response !== undefined) {
      toast.success("Updated Successfully");
    }
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1 className="t-t-c">{pageName?.replaceAll("_", " ")} Content</h1>
        </div>

        {isQuillOnlyPage && (
          <div className="mt-3">
            <label>Description</label>
            <ReactQuill
              modules={modules}
              placeholder="Enter Here..."
              value={description}
              onChange={(e) => {
                setDescription(e);
              }}
            />

            {/* button */}
            <div className="cms-btn-container">
              <Button
                className="update-btn"
                onClick={handleSubmitFooter}
                disabled={isApiCall}
              >
                {isApiCall ? "Please Wait..." : "Submit"}
              </Button>
            </div>
          </div>
        )}

        {!isQuillOnlyPage && (
          <div className="fieldSetMainContainer mt-3">
            {/* Table */}
            {isGetting ? (
              <TableRowsLoader />
            ) : (
              <div className="col-md-12">
                {!["customer_support", "footer"].includes(pageName) && (
                  <>
                    {apiData && pageName === "home" && (
                      <CMSTriangleDataMananger
                        activeRef={section1Ref}
                        title={apiData?.span}
                        titleLabel={"Title Left"}
                        title2={apiData?.heading}
                        title2Label={"Title Right"}
                        description={apiData?.detail_description}
                        descriptionLabel={"Enter Description"}
                        image={apiData?.coverImage}
                      />
                    )}
                    {/* Section 1 */}
                    {apiData && (
                      <CMSTriangleDataMananger
                        activeRef={section2Ref}
                        title={apiData?.sec1Heading}
                        titleLabel={"Section 1 Heading"}
                        description={apiData?.sec1Description}
                        descriptionLabel={"Enter Description"}
                        image={apiData?.sec1Image}
                        title3={apiData?.title ? apiData?.title : null}
                        title3Label={
                          apiData?.title ? apiData?.title : "State Heading"
                        }
                        description3={
                          apiData?.description ? apiData?.description : null
                        }
                        description3Label={
                          apiData?.description ? "State Description" : null
                        }
                        heading={"Section 1"}
                        hr
                      />
                    )}
                    {/* button */}
                    <div className="cms-btn-container">
                      <Button
                        className="update-btn"
                        onClick={handleSubmit}
                        disabled={isApiCall}
                      >
                        {isApiCall ? "Please Wait..." : "Submit"}
                      </Button>
                    </div>
                  </>
                )}
                {/* // Footer */}
                {pageName === "footer" && (
                  <>
                    <textarea
                      className="form-control me-3"
                      rows={5}
                      placeholder="Enter Footer Content"
                      value={footer}
                      onChange={(e) => setFooter(e.target.value)}
                    />
                    {/* button */}
                    <div className="cms-btn-container">
                      <Button
                        className="update-btn"
                        onClick={handleSubmitFooter}
                        disabled={isApiCall}
                      >
                        {isApiCall ? "Please Wait..." : "Submit"}
                      </Button>
                    </div>
                  </>
                )}
                {/* Contact Support */}
                {pageName === "customer_support" && (
                  <>
                    <Row>
                      {/* Heading Left */}
                      <div className="mt-3 col-md-6">
                        <label>HeadingL Left</label>
                        <input
                          className="add-evidence-modal-input full"
                          type="text"
                          placeholder={`Header Left Here`}
                          value={headingLeft}
                          onChange={(e) => {
                            setHeadingLeft(e.target.value);
                          }}
                        />
                      </div>
                      {/* Heading Right */}
                      <div className="mt-3 col-md-6">
                        <label>Heading Right</label>
                        <input
                          className="add-evidence-modal-input full"
                          type="text"
                          placeholder={`Header Right Here`}
                          value={headingRight}
                          onChange={(e) => {
                            setHeadingRight(e.target.value);
                          }}
                        />
                      </div>
                      {/* Address */}
                      <div className="mt-3 col-md-6">
                        <label>Address</label>
                        <input
                          className="add-evidence-modal-input full"
                          type="text"
                          placeholder={`Address Here`}
                          value={headQuarters}
                          onChange={(e) => {
                            setHeadQuarters(e.target.value);
                          }}
                        />
                      </div>
                      {/* Email */}
                      <div className="mt-3 col-md-6">
                        <label>Email</label>
                        <input
                          className="add-evidence-modal-input full"
                          type="text"
                          placeholder={`Email Here`}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      {/* Phone Number */}
                      <div className="mt-3 col-md-6">
                        <label>Phone Number</label>
                        <input
                          className="add-evidence-modal-input full"
                          type="text"
                          placeholder={`Phone Number Here`}
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </div>
                      {/* serviceTimings */}
                      <div className="mt-3 col-md-12">
                        <label>Service Timings</label>
                        <textarea
                          className="form-control me-3"
                          rows={5}
                          placeholder="Service Timings Here"
                          value={serviceHours}
                          onChange={(e) => setServiceHours(e.target.value)}
                        />
                      </div>
                    </Row>
                    {/* button */}
                    <div className="cms-btn-container">
                      <Button
                        className="update-btn"
                        onClick={handleSubmitFooter}
                        disabled={isApiCall}
                      >
                        {isApiCall ? "Please Wait..." : "Submit"}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </section>
    </ScreenBoiler>
  );
}

export default CMSDetail;
