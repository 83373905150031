import React, { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { formRegEx, formRegExReplacer } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";
import { IconButton, TextField } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CheckIcon from "@mui/icons-material/Check";

const AddAndEditEvaluationQuestionModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
}) => {
  // states
  const [question, setQuestion] = useState(data?.question || "");
  const [answers, setAnswersArray] = useState(data?.answers || [""]);

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    let _answers = answers.filter((e) => e != "");
    if (_answers.length === 0)
      return toast.warn("Options/Answers Are required");

    const params = {
      question,
      answers: _answers,
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    handleSubmit(params);
  };

  // handleAddDesc
  const handleAddDesc = () => {
    setAnswersArray([...answers, ""]);
  };

  // renderOptions
  const renderOptions = () => {
    return (
      <div className="mt-3">
        <div className="d-flex align-items-center justify-content-between">
          <label>Answers</label>
          <label className="c-p colors-default" onClick={handleAddDesc}>
            Add More
          </label>
        </div>
        {answers.map((e, i) => (
          <div key={i} className="d-flex align-items-center">
            <div className="flex-grow-1">
              <TextField
                value={e}
                onChange={(e) => {
                  const newArray = [...answers];
                  newArray[i] = e.target.value;
                  setAnswersArray(newArray);
                }}
                variant="outlined"
                multiline
                rows={1}
                rowsMax={1}
                fullWidth
                placeholder="Answer"
                className="mt-2"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Question
        </span>

        {/* Question */}
        <div className="mt-3">
          <label>Question</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Question Here"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
        </div>

        {/* render options */}
        {renderOptions()}

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditEvaluationQuestionModal;
