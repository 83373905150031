import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import ModalSkeleton from "./ModalSkeleton";
import { IconButton } from "@mui/material";
import { Button, Col, Row } from "react-bootstrap";
import moment from "moment";
import { numberRegEx } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { countyData } from "../Config/county";

const EditUserInfoModal = ({ open, setOpen, data, isLoading, onClick }) => {
  const [firstName, setFirstName] = useState(data?.firstName || "");
  const [lastName, setLastName] = useState(data?.lastName || "");
  const [address, setAddress] = useState(data?.address || "");
  const [licenseNumber, setLicenseNumber] = useState(data?.licenseNumber || "");
  const [dateOfBirth, setDateOfBirth] = useState(
    moment(data?.dateOfBirth).format("YYYY-MM-DD") || ""
  );
  const [city, setCity] = useState(data?.city || "");
  // const [licenseState, setLicenseState] = useState(data?.licenseState || "");
  const [zipCode, setZipCode] = useState(data?.zipCode || "");
  const [certificateState, setCertificateState] = useState(
    data?.certificateState || data?.state?.state
  );
  const [courtName, setCourtName] = useState(data?.courtName || "");

  // new States
  const [licenseState, setLicenseState] = useState(data?.licenseState || "");
  const [caseNumber, setCaseNumber] = useState(data?.caseNumber || "");

  // state => california
  const [county, setCounty] = useState(data?.county || "");

  // handleClick
  const handleClick = () => {
    const params = {
      firstName,
      lastName,
      address,
      licenseNumber,
      dateOfBirth: moment(dateOfBirth).format(),
      city,
      // licenseState,
      zipCode,
      certificateState,
      courtName,

      ...(data?.firstName !== firstName && { oldFirstName: data?.firstName }),
      ...(data?.lastName !== lastName && { oldLastName: data?.lastName }),
      ...(data?.address !== address && { oldAddress: data?.address }),
      ...(data?.licenseNumber !== licenseNumber && {
        oldLicenseNumber: data?.licenseNumber,
      }),
      ...(moment(data?.dateOfBirth).format("YYYY-MM-DD") !==
        moment(dateOfBirth).format("YYYY-MM-DD") && {
        oldDateOfBirth: data?.dateOfBirth,
      }),
      ...(data?.city !== city && { oldCity: data?.city }),
      // ...(data?.licenseState !== licenseState && {
      //   oldLicenseState: data?.licenseState,
      // }),
      ...(data?.zipCode !== zipCode && { oldZipCode: data?.zipCode }),
      ...(data?.certificateState !== certificateState && {
        oldCertificateState: data?.certificateState || data?.state?.state,
      }),
      ...(data?.courtName !== courtName && { oldCourtName: data?.courtName }),

      // new States
      licenseState,
      caseNumber,
      ...(data?.state?.state?.toLowerCase() === "california" && {
        county,
      }),
    };

    if (
      !firstName ||
      !lastName ||
      !address ||
      !licenseNumber ||
      !dateOfBirth ||
      !city ||
      // !licenseState ||
      !zipCode
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    onClick(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen} type="big">
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4 t-t-c">
          {[data?.firstName, data?.lastName].join(" ")}
        </span>

        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>First Name (Current)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="First Name (Current)"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>First Name (Changed From)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="First Name (Changed From)"
                value={data?.oldFirstName}
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>Last Name (Current)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Last Name (Current)"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>Last Name (Changed From)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Last Name (Changed From)"
                value={data?.oldLastName}
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>Address (Current)</label>
              <textarea
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Address (Current)"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                rows={3}
                style={{
                  height: "auto",
                  padding: "10px 15px",
                  resize: "none",
                }}
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>Address (Changed From)</label>
              <textarea
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Address (Changed From)"
                value={data?.oldAddress}
                disabled
                rows={3}
                style={{
                  height: "auto",
                  padding: "10px 15px",
                  resize: "none",
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>License Number (Current)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="License Number (Current)"
                value={licenseNumber}
                onChange={(e) => {
                  setLicenseNumber(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>License Number (Changed From)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="License Number (Changed From)"
                value={data?.oldLicenseNumber}
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>Date of Birth (Current)</label>
              <input
                className="add-evidence-modal-input full"
                type="date"
                placeholder="Date of Birth (Current)"
                value={dateOfBirth}
                onChange={(e) => {
                  setDateOfBirth(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>Date of Birth (Changed From)</label>
              <input
                className="add-evidence-modal-input full"
                type="date"
                placeholder="Date of Birth (Changed From)"
                value={
                  data?.oldDateOfBirth
                    ? moment(data?.oldDateOfBirth).format("YYYY-MM-DD")
                    : null
                }
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>City (Current)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="City (Current)"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>City (Changed From)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="City (Changed From)"
                value={data?.oldCity ? data?.oldCity : null}
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>Certificate State (Current)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Certificate State (Current)"
                value={certificateState}
                onChange={(e) => {
                  setCertificateState(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>Certificate State (Changed From)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Certificate State (Changed From)"
                value={
                  data?.oldCertificateState ? data?.oldCertificateState : null
                }
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>Zip Code (Current)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Zip Code (Current)"
                value={zipCode}
                onChange={(e) => {
                  setZipCode(e?.target?.value?.replace(numberRegEx, ""));
                }}
                maxLength="5"
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>Zip Code (Changed From)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Zip Code (Changed From)"
                value={data?.oldZipCode ? data?.oldZipCode : null}
                disabled
              />
            </div>
          </Col>
        </Row>
        {/* Court Name */}
        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>Court Name (Current)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Court Name (Current)"
                value={courtName}
                onChange={(e) => {
                  setCourtName(e?.target?.value);
                }}
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>Court Name (Changed From)</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Court Name (Changed From)"
                value={data?.oldCourtName ? data?.oldCourtName : null}
                disabled
              />
            </div>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col md={6} className="mb-3">
            <div>
              <label>License State</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="License State"
                value={licenseState}
                onChange={(e) => {
                  setLicenseState(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div>
              <label>Case ID</label>
              <input
                className="add-evidence-modal-input full"
                type="text"
                placeholder="Case ID"
                value={caseNumber}
                onChange={(e) => {
                  setCaseNumber(e.target.value);
                }}
              />
            </div>
          </Col>
          {data?.state?.state?.toLowerCase() === "california" && (
            <Col md={6} className="mb-3">
              <div>
                <label>County</label>
                <select
                  className="add-evidence-modal-input full"
                  type="text"
                  placeholder="County"
                  value={county}
                  onChange={(e) => {
                    setCounty(e.target.value);
                  }}
                >
                  {countyData?.map((item, index) => (
                    <option
                      key={index}
                      value={item?.county}
                      label={item?.county}
                    />
                  ))}
                </select>
              </div>
            </Col>
          )}
        </Row>

        <div className="d-flex justify-content-end mt-3">
          <Button
            className="update-btn w-160px"
            disabled={isLoading}
            onClick={handleClick}
          >
            {isLoading ? "Updating" : "Update"}
          </Button>
        </div>

        <IconButton className="close-icon-modal" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </form>
    </ModalSkeleton>
  );
};

export default EditUserInfoModal;
