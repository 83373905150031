import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import {
  BaseURL,
  apiHeader,
  imageUrl,
  pdfUrlUrl,
  recordsLimit,
} from "../Config/apiUrl";
import AreYouSureModal from "../components/AreYouSureModal";
import TableRowsLoader from "../components/TableRowsLoader";
// Mui Button
import { CardMedia } from "@mui/material";
import MuiButton from "@mui/material/Button";
import axios from "axios";
import { AiOutlineFilePdf } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddAndEditTopicModal from "../components/AddAndEditTopicModal";
import ScreenBoiler from "../components/ScreenBoiler";

function TopicScreen() {
  const params = useParams();
  const [limit, setLimit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isAddEditApiCall, setIsAddEditApiCall] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [questionArray, setQuestionArray] = useState([]);
  const [activeInActiveApiCall, setActiveInActiveApiCall] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);

  const [gettingTopicId, setGettingTopicId] = useState(null);

  // get Item handler
  const getData = async (pageNo, newLimit) => {
    const url = BaseURL(
      `lesson/admin/${params?.id}?page=${pageNo}&limit=${newLimit}`
    );

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
    }
  };

  // addAndupdateItemHandler
  const addAndupdateItemHandler = async (params) => {
    const formData = new FormData();
    formData.append("topicName", params.topicName);
    // formData.append("description", params.description);
    formData.append("hours", params.hours);
    formData.append("minutes", params.minutes);
    formData.append("descriptionFile", params.descriptionFile);

    if (params?.pdf?.length !== 0) {
      for (let key in params.pdf) formData.append("pdf", params.pdf[key]);
    }
    formData.append("oldPdf", JSON.stringify(params.oldPdf));
    if (params?.images?.length !== 0) {
      for (let key in params.images)
        formData.append("images", params.images[key]);
    }
    formData.append("oldImages", JSON.stringify(params.oldImages));
    formData.append("lesson", params.lesson);

    // return console.log(params?.images, params?.oldImages)

    if (
      params?.quiz?.quizName !== "" ||
      params?.quiz?.duration !== "" ||
      params?.quiz?.questions !== ""
    ) {
      formData.append("quiz", JSON.stringify(params.quiz));
    } else {
      formData.append("quiz", null);
    }
    params.isVideo
      ? formData.append("isVideo", true)
      : formData.append("isVideo", false);

    const url = BaseURL(
      editingData ? `topic/admin/${editingData?._id}` : `topic/admin`
    );
    const fn = editingData?._id ? Patch : Post;

    setIsAddEditApiCall(true);
    const response = await fn(url, formData, apiHeader(accessToken, true));
    setIsAddEditApiCall(false);
    let result;

    if (response !== undefined) {
      // if we have a video
      if (params.isVideo) {
        result = await awsVideoUploader(
          response?.data?.data?.url,
          params.video
        );
      }
      // if we have updated
      if (editingData) {
        const dataCopy = { ...apiData };
        const index = dataCopy.topics.findIndex(
          (item) => item._id === editingData._id
        );
        dataCopy.topics[index] = response.data?.data?.populatedTopic;
        setApiData(dataCopy);
      }
      // if we have added
      else {
        setApiData((p) => {
          return {
            ...p,
            topics: [...p.topics, response.data?.data?.populatedTopic],
          };
        });
      }

      toast.success(
        `Topic ${result?.status === 200 ? "And Video" : ""} ${
          editingData ? "Updated" : "Added"
        } Successfully`
      );
      setEditingData(null);
      setIsAddAndEditModalOpen(false);
      setVideoProgress(0);
    } else {
      for (let key in params.pdf) formData.delete("pdf");
      formData.delete("oldPdf");
      formData.delete("quiz");
      formData.delete("video");
      formData.delete("lesson");
      formData.delete("topicName");
    }
  };

  // deleteItemHandler
  const deleteItemHandler = async () => {
    const url = BaseURL(`topic/admin/${deletingId}`);

    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = { ...apiData };
      const index = dataCopy.topics.findIndex(
        (item) => item._id === deletingId
      );
      dataCopy.topics.splice(index, 1);
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(`Topic deleted succeccfully!`);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData(1, limit);
    getQuestionData();
  }, []);

  // get Item handler
  const getQuestionData = async () => {
    const url = BaseURL(`question/lesson/${params?.id}?noPagination=true`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setQuestionArray(response?.data?.data);
    }
  };

  // handleActiveInactive
  const handleActiveInactive = async (isActive, index, id) => {
    const url = BaseURL(`topic/admin/${id}`);
    const params = {
      isActive,
    };

    setActiveInActiveApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setActiveInActiveApiCall(false);

    if (response !== undefined) {
      const dataCopy = { ...apiData };
      dataCopy.topics[index].isActive = isActive;
      setApiData(dataCopy);
      toast.success(
        `Topic ${isActive ? "Activated" : "Deactivated"} Successfully`
      );
    }
  };

  //  awsVideoUploader
  const awsVideoUploader = async (awsUrl, videoFile) => {
    setIsAddEditApiCall(true);
    const result = await axios.put(awsUrl, videoFile, {
      headers: {
        "Content-Type": videoFile.type,
      },
      onUploadProgress: (data) => {
        setVideoProgress(Math.round((100 * data.loaded) / data.total));
      },
    });
    setIsAddEditApiCall(false);
    if (result?.status !== 200) {
      toast.error(`Video upload failed`);
    }

    return result;
  };

  // get single topic detail handler
  const getSingleTopicDetailHandler = async (id) => {
    const url = BaseURL(`topic/${id}`);

    setGettingTopicId(id);
    const response = await Get(url, accessToken);
    setGettingTopicId(null);

    if (response !== undefined) {
      setEditingData(response?.data?.data);
      setIsAddAndEditModalOpen(true);
    }
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Topics of {apiData?.lessonName}</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => {
                  setEditingData(null);
                  setIsAddAndEditModalOpen(true);
                }}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div>
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Topic Name</th>
                      <th>Video</th>
                      <th>Pdfs</th>
                      <th>Images</th>
                      <th>Active</th>
                      <th>Last Updated</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.topics?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{++index}</td>
                          <td className="t-t-c">{item?.topicName}</td>
                          <td>
                            {item?.video ? (
                              <CardMedia
                                component="video"
                                height="194"
                                controls
                                autoplay
                                src={item?.video}
                              />
                            ) : (
                              "Not added yet"
                            )}
                          </td>
                          <td>
                            {item?.pdf.map((e, i) => (
                              <div key={i}>
                                <a
                                  href={pdfUrlUrl(e)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="c-p"
                                >
                                  <AiOutlineFilePdf size={20} />
                                </a>
                                <br />
                              </div>
                            ))}
                          </td>
                          <td>
                            <div className="imageTd">
                              {item?.images?.map((e, i) => (
                                <div key={i}>
                                  <img
                                    onClick={() =>
                                      window.open(imageUrl(e), "_blank")
                                    }
                                    src={imageUrl(e)}
                                    alt="img"
                                    className="img-fluid me-1 mb-1"
                                    style={{ width: "50px" }}
                                  />
                                  <br />
                                </div>
                              ))}
                            </div>
                          </td>
                          <td>{item?.isActive ? "Yes" : "No"}</td>
                          <td>
                            {moment(item?.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end me-2"
                                onClick={() => {
                                  getSingleTopicDetailHandler(item?._id);
                                }}
                              >
                                <Button className="update-btn">
                                  {item?._id === gettingTopicId
                                    ? "Wait..."
                                    : "Edit"}
                                </Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsAreYouSureModalOpen(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4 ms-2"
                                onClick={handleActiveInactive.bind(
                                  this,
                                  !item?.isActive,
                                  index - 1,
                                  item?._id
                                )}
                              >
                                <Button
                                  className="update-btn"
                                  disabled={activeInActiveApiCall}
                                >
                                  {item?.isActive ? "Deactivate" : "Activate"}
                                </Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.topics?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={`Do you really want to delete this Topics?`}
            handleYes={deleteItemHandler}
            isLoading={isDeleteApiCall}
          />
        )}

        {isAddAndEditModalOpen && (
          <AddAndEditTopicModal
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            handleSubmit={addAndupdateItemHandler}
            data={editingData}
            isLoading={isAddEditApiCall}
            questionArray={questionArray}
            lesson={params?.id}
            videoProgress={videoProgress}
            setApiData={setApiData}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default TopicScreen;
