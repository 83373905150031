import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import AreYouSureModal from "../components/AreYouSureModal";
import Pagination from "../components/PaginationComponent";
import TableRowsLoader from "../components/TableRowsLoader";
// Mui Button
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import AddEditEditCertificatesModal from "../components/AddEditEditCertificatesModal";
import ScreenBoiler from "../components/ScreenBoiler";

import classes from "./CertificatesQuantityScreen/CertificatesQuantityScreen.module.css";

function CertificatesScreen() {
  const [limit, setLimit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, settotal] = useState(0);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isAddEditApiCall, setIsAddEditApiCall] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [certificateCount, setCertificateCount] = useState(0);

  // get Item handler
  const getData = async (pageNo) => {
    const url = BaseURL(`certificate/admin/all?page=${pageNo}&limit=${limit}`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
      settotal(response?.data?.totalRecords);
    }
  };

  // addAndupdateItemHandler
  const addAndupdateItemHandler = async (params) => {
    const url = BaseURL(
      editingData ? `certificate/${editingData?._id}` : `certificate/create`
    );
    const fn = editingData?._id ? Patch : Post;

    setIsAddEditApiCall(true);
    const response = await fn(url, params, apiHeader(accessToken));
    setIsAddEditApiCall(false);

    if (response !== undefined) {
      // if we have updated
      if (editingData) {
        const dataCopy = [...apiData];
        const index = dataCopy.findIndex(
          (item) => item._id === editingData._id
        );
        dataCopy[index] = response.data?.data;
        setApiData(dataCopy);

        if (response.data?.data?.type !== "unused") {
          setCertificateCount((p) => p - 1);
        }
      }
      // if we have added
      else {
        setApiData((p) => [response.data?.data, ...p]);
        setCertificateCount((p) => p + 1);
      }

      toast.success(
        `Certificate ${editingData ? "Updated" : "Added"} Successfully`
      );
      setEditingData(null);
      setIsAddAndEditModalOpen(false);
    }
  };

  // deleteItemHandler
  const deleteItemHandler = async () => {
    const url = BaseURL(`certificate/${deletingId}`);

    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === deletingId);
      dataCopy.splice(index, 1);
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(`Certificate deleted successfully!`);
      setCertificateCount((p) => p - 1);
      setDeletingId(null);
    }
  };

  // get certiticate count handler
  const getCertificateCountHandler = async () => {
    const url = BaseURL(`certificate/unused-count`);

    const response = await Get(url);
    if (response !== undefined) {
      setCertificateCount(response?.data?.totalRecords);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber]);
  useEffect(() => {
    getCertificateCountHandler();
  }, []);

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Texas Certificates</h1>
        </div>

        <div className="container pt-5 pb-5">
          <div className="row align-items-stretch gy-4 justify-content-center">
            <div
              className={`${classes.cInfo} col-lg-3 col-md-4 col-sm-6 col-xs-12`}
            >
              <div className={classes.wrap}>
                <h4
                  className={`heading heading5 hind-font medium-font-weight ${classes.cInfo__title}`}
                >
                  Total Remaining Certificates
                </h4>
                <span
                  className={`hind-font caption-12 ${classes.cInfo__count}`}
                >
                  {certificateCount}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center"></div>

            <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => {
                  setEditingData(null);
                  setIsAddAndEditModalOpen(true);
                }}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div>
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Certificate Id</th>
                      <th>Status</th>
                      <th>Last Updated</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td className="t-t-c">{item?.certificateId}</td>
                          <td>{item?.type}</td>

                          <td>
                            {moment(item?.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end me-2"
                                onClick={() => {
                                  setEditingData(item);
                                  setIsAddAndEditModalOpen(true);
                                }}
                              >
                                <Button className="update-btn">Edit</Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsAreYouSureModalOpen(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* <Pagination
            maxLastLength={limit}
            array={apiData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          /> */}
          <div className="page">
            {apiData.length > 0 && (
              <Pagination
                totalPages={Math.ceil(total / limit)}
                setCurrentPage={setPageNumber}
                currentPage={pageNumber}
              />
            )}
          </div>
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={`Do you really want to delete this certificate?`}
            handleYes={deleteItemHandler}
            isLoading={isDeleteApiCall}
          />
        )}

        {isAddAndEditModalOpen && (
          <AddEditEditCertificatesModal
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            handleSubmit={addAndupdateItemHandler}
            data={editingData}
            isLoading={isAddEditApiCall}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default CertificatesScreen;
