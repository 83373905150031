import React, { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { formRegEx, formRegExReplacer } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";
import { IconButton, TextField } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";

const AddAndEditPackageModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
  states: statesData,
  courses: coursesData,
}) => {
  // states
  const [type, setType] = useState(data?.type || "");
  const [price, setPrice] = useState(data?.price || "");
  const [cutPrice, setCutPrice] = useState(data?.cutPrice || "");
  const [state, setState] = useState(data?.state || "");
  const [course, setCourse] = useState(data?.courses || "");
  const [descriptionArray, setDescriptionArray] = useState(
    data?.description || [""]
  );

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    if (state.length === 0) return toast.warn("State is required");
    if (course.length === 0) return toast.warn("Course is required");
    let description = descriptionArray.filter((e) => e != "");
    if (description.length === 0) return toast.warn("Description is required");

    const params = {
      type,
      price,
      state: state.map((e) => e?._id),
      courses: course.map((e) => e?._id),
      description,
      cutPrice,
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    if (isNaN(price)) return toast.warn("Price should be a number.");
    if (isNaN(cutPrice)) return toast.warn("Old price should be a number.");

    if (Number(price) >= Number(cutPrice))
      return toast.warn("New Price should be less than old price.");

    handleSubmit(params);
  };

  // handleAddDesc
  const handleAddDesc = () => {
    setDescriptionArray([...descriptionArray, ""]);
  };

  // renderDescriptions
  const renderDescriptions = () => {
    return (
      <div className="mt-3">
        <div className="d-flex align-items-center justify-content-between">
          <label>Description</label>
          <label className="c-p colors-default" onClick={handleAddDesc}>
            Add More
          </label>
        </div>
        {descriptionArray.map((e, i) => (
          <div key={i} className="d-flex align-items-center">
            <div className="flex-grow-1">
              <TextField
                value={e}
                onChange={(e) => {
                  const newArray = [...descriptionArray];
                  newArray[i] = e.target.value;
                  setDescriptionArray(newArray);
                }}
                variant="outlined"
                multiline
                rows={1}
                rowsMax={1}
                fullWidth
                placeholder="Description"
                className="mt-2"
              />
            </div>
            <div className="flex-shrink-0">
              <IconButton
                onClick={() => {
                  const newArray = [...descriptionArray];
                  newArray.splice(i, 1);
                  setDescriptionArray(newArray);
                }}
                className="mt-2"
              >
                <AiOutlineCloseCircle />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Package
        </span>

        {/* Price */}
        <div className="mt-3">
          <label>Price</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            placeholder="Price Here"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
        </div>
        {/* cutPrice */}
        <div className="mt-3">
          <label>Old Price</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            placeholder="Old Price Here"
            value={cutPrice}
            onChange={(e) => {
              setCutPrice(e.target.value);
            }}
          />
        </div>

        {/* States */}
        <div className="mt-3">
          <label>Select States</label>
          <Select
            defaultValue={state}
            isMulti
            options={statesData}
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={(option) => option.state}
            getOptionValue={(option) => option._id}
            onChange={(e) => {
              setState(e);
            }}
          />
        </div>

        {/* Courses */}
        <div className="mt-3">
          <label>Select Courses</label>
          <Select
            defaultValue={course}
            isMulti
            options={coursesData}
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={(option) => option.courseName}
            getOptionValue={(option) => option._id}
            onChange={(e) => {
              setCourse(e);
            }}
          />
        </div>

        {/* type */}
        <div className="mt-3">
          <label>Package Type</label>
          <FormControl component="fieldset" className="d-b">
            <RadioGroup
              row
              aria-label="status"
              name="controlled-radio-buttons-group"
              value={type}
              onChange={(e) => setType(e?.target?.value)}
            >
              <FormControlLabel
                value={"value"}
                control={<Radio />}
                label="Value"
              />
              <FormControlLabel
                value={"preferred"}
                control={<Radio />}
                label="Preferred"
              />
              <FormControlLabel
                value={"premium"}
                control={<Radio />}
                label="Premium"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {/* render decriptions */}
        {renderDescriptions()}

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditPackageModal;
