import React from "react";
import Pagination from "@mui/material/Pagination";

const PaginationComponent = ({
  totalPages,
  currentPage,
  setCurrentPage,
  defaultActiveColor = "rgb(6, 175, 6)",
  bgColor = "rgb(6, 175, 6)",
}) => {
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <>
      {/* // change color of pagination: this is temp fix */}
      <style>{`
      .MuiPagination-ul li {
        // background: rgb(6, 175, 6) !important;
        border-radius:5px
      }
        .MuiPagination-ul li .Mui-selected,  .MuiPagination-ul li .Mui-selected:hover {
            background: ${defaultActiveColor} !important;
            color: var(--white-color) !important;
            font-size:16px !important;
            min-width: 30px !important;
            height: 30px !important;
            font-family:"Redtone-SemiBold" !important;
        }
        .MuiPagination-ul li button, .MuiPagination-ul li button:hover {
            color: var(--text-color-black) !important;
            font-size:16px !important;
            font-family:"Redtone-SemiBold" !important;
            border-radius: 5px;
            background-color:rgb(6 175 6 / 8%);
        }
        .MuiPagination-ul li button svg{
            color: var(--text-color-black) !important;
            
        }
    `}</style>
      <div>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default PaginationComponent;
