// export const apiUrl = "https://nobodylikes-be.herokuapp.com";

// export const apiUrl = "https://bfaa-119-155-153-170.ngrok-free.app"; // old
export const apiUrl = "https://backend.nobodylikestickets.com"; // [Current]

// export const apiUrl = "https://1be8-39-51-70-137.ngrok-free.app"; //usama
// export const apiUrl = "https://3pmq3hk5-3010.inc1.devtunnels.ms"; //talal

export const imageUrl = (url) => `${apiUrl}/api/images/${url}`;
export const pdfUrlUrl = (url) => `${apiUrl}/api/pdf/${url}`;

export const BaseURL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const recordsLimit = 50;

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";

export function isEmptyObject(obj) {
  return Object.keys(obj).length == 0;
}

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const numberRegEx = /[^0-9]+/g;
