import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import AreYouSureModal from "../components/AreYouSureModal";
import Pagination from "../components/PaginationComponent";
import TableRowsLoader from "../components/TableRowsLoader";
// Mui Button
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import AddAndEditCourseModal from "../components/AddAndEditCourseModal";
import ScreenBoiler from "../components/ScreenBoiler";

function CoursesScreen() {
  const [limit, setLimit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [total, setTotal] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isAddEditApiCall, setIsAddEditApiCall] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [examArray, setExamArray] = useState([]);
  const [lessonArray, setLessonArray] = useState([]);
  const [activeInActiveApiCall, setActiveInActiveApiCall] = useState(false);

  // get Item handler
  const getData = async (pageNo) => {
    const url = BaseURL(`course?page=${pageNo}&limit=${limit}`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
      setTotal(response?.data?.totalRecords);
    }
  };

  // addAndupdateItemHandler
  const addAndupdateItemHandler = async (params) => {
    const url = BaseURL(
      editingData ? `course/admin/${editingData?._id}` : `course/admin`
    );
    const fn = editingData?._id ? Patch : Post;

    setIsAddEditApiCall(true);
    const response = await fn(url, params, apiHeader(accessToken));
    setIsAddEditApiCall(false);

    if (response !== undefined) {
      // if we have updated
      if (editingData) {
        const dataCopy = [...apiData];
        const index = dataCopy.findIndex(
          (item) => item._id === editingData._id
        );
        dataCopy[index] = response.data?.data;
        setApiData(dataCopy);
      }
      // if we have added
      else {
        setApiData((p) => [response.data?.data, ...p]);
      }

      toast.success(`Course ${editingData ? "Updated" : "Added"} Successfully`);
      setEditingData(null);
      setIsAddAndEditModalOpen(false);
    }
  };

  // deleteItemHandler
  const deleteItemHandler = async () => {
    const url = BaseURL(`course/admin/${deletingId}`);

    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === deletingId);
      dataCopy.splice(index, 1);
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(`Course deleted succeccfully!`);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData(pageNumber);
    getExamsAndLessons();
  }, [pageNumber]);

  // pagination handler
  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getData(newPageNumber, limit);
  };

  // get exams and lessons
  const getExamsAndLessons = async () => {
    const url = BaseURL(`exam/admin?noPagination=true`);
    const url2 = BaseURL(`lesson?noPagination=true`);

    const [response1, response2] = await Promise.all([
      Get(url, accessToken),
      Get(url2, accessToken),
    ]);
    if (response1 !== undefined && response2 !== undefined) {
      setExamArray(response1?.data?.data);
      setLessonArray(response2?.data?.data);
    }
  };

  // handleActiveInactive
  const handleActiveInactive = async (isActive, index, id) => {
    const url = BaseURL(`course/admin/${id}`);
    const params = {
      isActive,
    };

    setActiveInActiveApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setActiveInActiveApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      dataCopy[index].isActive = isActive;
      setApiData(dataCopy);
      toast.success(
        `Course ${isActive ? "Activated" : "Deactivated"} Successfully`
      );
    }
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Courses</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              {/* <p className="m-0 me-3 fw-bold"> </p> */}
              {/* <select
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPageNumber(1);
                  HandlepageChange(1, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={10} className="pt-2">
                  10
                </option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select> */}
            </div>

            <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => {
                  setEditingData(null);
                  setIsAddAndEditModalOpen(true);
                }}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div>
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>Number Of Lessons</th>
                      <th>Exam Name</th>
                      <th>Last Updated</th>
                      <th>Active</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.map((item, index) => {
                      const exams = item?.exam
                        ?.filter((e) => e?.examType === "original")
                        ?.map((e) => e?.examName);
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td className="t-t-c">{item?.courseName}</td>
                          <td>{item?.lessons?.length}</td>
                          <td>{exams.join(", ")}</td>
                          <td>
                            {moment(item?.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>{item?.isActive ? "Yes" : "No"}</td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end me-2"
                                onClick={() => {
                                  setEditingData(item);
                                  setIsAddAndEditModalOpen(true);
                                }}
                              >
                                <Button className="update-btn">Edit</Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsAreYouSureModalOpen(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4 ms-2"
                                onClick={handleActiveInactive.bind(
                                  this,
                                  !item?.isActive,
                                  index - 1,
                                  item?._id
                                )}
                              >
                                <Button
                                  className="update-btn w-120px"
                                  disabled={activeInActiveApiCall}
                                >
                                  {item?.isActive ? "Deactivate" : "Activate"}
                                </Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* <Pagination
            maxLastLength={limit}
            array={apiData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          /> */}

          <div className="page">
            {apiData.length > 0 && (
              <Pagination
                totalPages={Math.ceil(total / limit)}
                setCurrentPage={setPageNumber}
                currentPage={pageNumber}
              />
            )}
          </div>
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={`Do you really want to delete this Course?`}
            handleYes={deleteItemHandler}
            isLoading={isDeleteApiCall}
          />
        )}

        {isAddAndEditModalOpen && (
          <AddAndEditCourseModal
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            handleSubmit={addAndupdateItemHandler}
            data={editingData}
            isLoading={isAddEditApiCall}
            examArray={examArray}
            lessonArray={lessonArray}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default CoursesScreen;
