import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Delete, Get, Post } from "../Axios/AxiosFunctions";
import { BaseURL, apiHeader, imageUrl, recordsLimit } from "../Config/apiUrl";
import AreYouSureModal from "../components/AreYouSureModal";
import Pagination from "../components/PaginationComponent";
import TableRowsLoader from "../components/TableRowsLoader";
// Mui Button
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import GenerateReportModal from "../components/GenerateReportModal";
import ScreenBoiler from "../components/ScreenBoiler";

function Reports() {
  const [limit, setLimit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, settotal] = useState(0);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isAddEditApiCall, setIsAddEditApiCall] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  // get Item handler
  const getData = async (pageNo) => {
    const url = BaseURL(
      `users/getAllReportAdmin?page=${pageNo}&limit=${limit}`
    );

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
      settotal(response?.data?.totalRecords);
    }
  };

  // generateReportHandler
  const generateReportHandler = async (params) => {
    const url = BaseURL(
      params?.reportType === "result"
        ? `users/generateReportofResults`
        : params?.reportType === "registration"
        ? `users/generateReportofUsers`
        : `users/generateDmv`
    );

    setIsAddEditApiCall(true);
    const response = await Post(url, params, apiHeader(accessToken));
    setIsAddEditApiCall(false);

    if (response !== undefined) {
      setApiData((p) => [response.data?.data, ...p]);

      toast.success(`Report generated successfully.`);
      setIsAddAndEditModalOpen(false);
    }
  };

  // deleteItemHandler
  const deleteItemHandler = async () => {
    const url = BaseURL(`users/deleteReport/${deletingId}`);

    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === deletingId);
      dataCopy.splice(index, 1);
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(`Report deleted succeccfully!`);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber]);

  // pagination handler
  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getData(newPageNumber, limit);
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Reports</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => {
                  setIsAddAndEditModalOpen(true);
                }}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Generate
              </MuiButton>
            </div>
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>File Name</th>
                      <th>Records From</th>
                      <th>Records To</th>
                      <th>Report Type</th>
                      <th>No. Of Records</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td className="t-t-c">{item?.fileName}</td>
                          <td>
                            {moment(item?.startDate).format("ddd, DD MMM YYYY")}
                          </td>
                          <td>
                            {moment(item?.endDate).format("ddd, DD MMM YYYY")}
                          </td>
                          <td>{item?.reportType}</td>
                          <td>{item?.TotalRecords}</td>
                          <td>
                            <ul className="d-flex">
                              <li
                                style={{
                                  width: "max-content",
                                }}
                                className="page-item align-self-end me-2"
                                onClick={() =>
                                  window.open(imageUrl(item?.file), "_blank")
                                }
                              >
                                <Button className="update-btn">
                                  Open File
                                </Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsAreYouSureModalOpen(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="page">
            {apiData.length > 0 && (
              <Pagination
                totalPages={Math.ceil(total / limit)}
                setCurrentPage={setPageNumber}
                currentPage={pageNumber}
              />
            )}
          </div>
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={`Do you really want to delete this report?`}
            handleYes={deleteItemHandler}
            isLoading={isDeleteApiCall}
          />
        )}

        {isAddAndEditModalOpen && (
          <GenerateReportModal
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            handleSubmit={generateReportHandler}
            isLoading={isAddEditApiCall}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default Reports;
